import { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from "../../components/ui/button";
import { useForm } from "react-hook-form";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import useNotificationHook from "../../hooks/notificationHooks";

export function SendNotification({ employees, sendToAll }) {
  const { sendToAllMutation, sendToFewMutation } = useNotificationHook();
  const { register, handleSubmit, formState, reset } = useForm();
  const { errors } = formState;

  const onSubmit = (data) => {

    if (sendToAll) {
      sendToAllMutation.mutate(data);
      reset();
    } else {
      sendToFewMutation.mutate({ ...data, to: employees });
      reset();
    }

    reset();
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button disabled={!employees.length > 0}>Send Notification</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogHeader>
            <AlertDialogTitle>Send Notification</AlertDialogTitle>
            <AlertDialogDescription>
              Notification will be sent to the selected employees
            </AlertDialogDescription>
          </AlertDialogHeader>

          <div>
            <div className="flex flex-col gap-4">
              <div>
                <label htmlFor="title">Title</label>
                <Input
                  type="text"
                  id="title"
                  {...register("title", {
                    required: {
                      value: true,
                      message: "Title is required",
                    },

                    validate: (value) =>
                      value.trim() !== "" || "Title is required",

                    minLength: {
                      value: 5,
                      message: "Title must be at least 5 characters",
                    },

                    maxLength: {
                      value: 100,
                      message: "Title must not exceed 100 characters",
                    },
                  })}
                />

                {errors.title && (
                  <span className="text-xs text-red-500">
                    {errors.title.message}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="body">Message</label>
                <Textarea
                  id="body"
                  className="resize-none"
                  {...register("body", {
                    required: {
                      value: true,
                      message: "Message is required",
                    },

                    validate: (value) =>
                      value?.trim() !== "" || "Message is required",

                    minLength: {
                      value: 5,
                      message: "Message must be at least 5 characters",
                    },

                    maxLength: {
                      value: 150,
                      message: "Message must not exceed 150 characters",
                    },
                  })}
                />

                {errors.body && (
                  <span className="error-message">{errors.body.message}</span>
                )}
              </div>
            </div>
          </div>

          <AlertDialogFooter className="py-2">
            <AlertDialogCancel>Cancel</AlertDialogCancel>

            {formState.isValid && (
              <AlertDialogAction type="submit">
                {" "}
                Send Notification
              </AlertDialogAction>
            )}
            {!formState.isValid && (
              <Button type="submit">Send Notification</Button>
            )}
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
