import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import useProjectHook from "../../hooks/useProjectHook";
import ProgressBar from "react-customizable-progressbar";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ProjectPerformanceChart = ({ projectId }) => {
  const [progress, setProgress] = useState([]);


  useEffect(() => {
    if(projectId!==''){

      fetchGrowthByProject();
    }
  }, [projectId]);

  const fetchGrowthByProject = async () => {
    try {
      const response = await axios.post(baseUrl + "/projectPerformance", {
        projectId: projectId,
      });
      setProgress(response?.data?.totalPercentage)
     console.log(response,'response')
    } catch (err) {
      console.log(err, "err growth");
    }
  };
  



  return (
    <div style={{margin:'auto',display:'flex',alignItems:'center',justifyContent:'center'}}>
       <ProgressBar
          radius={80}
          strokeWidth={12}
          strokeColor="#25CD25"
          strokeLinecap="round"
          trackStrokeWidth={12}
          counterClockwise
          progress={progress}
        >
          {({ text }) => (
            <div>
             
            </div>
          )}
              <div className="absolute inset-0 flex justify-center items-center text-lg font-bold text-gray-800">
                <div>
                  <h1 className="text-[#25CD25] font-[20-px] flex justify-center">
                    {progress} %
                  </h1>
                  {/* <p className="text-[gray] font-[10px]"> Completed</p> */}
                </div>
              </div>
            </ProgressBar>
    </div>
  );
};

export default ProjectPerformanceChart;
