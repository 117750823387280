import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import useNotificationHook from "../../hooks/notificationHooks";
import useLeaveHook from "../../hooks/useLeaveHook";

export const SendMessageModal = ({ isOpen, onClose, employeeId,statusData }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
const{statusChangeMutation}=useLeaveHook()
  const { sendToFewMutation } = useNotificationHook();

  const onSubmit = (data) => {
    onClose();
    const message = {
      title: data.title,
      body: data.body,
      to: employeeId,
    };

    reset();

    sendToFewMutation.mutate(message);
    statusChangeMutation.mutate(statusData)
  };
  const handleDialogChange = (open) => {
    if (!open) {
      // Prevent closing when clicking outside
      onClose();
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleDialogChange}>
      <DialogTitle>Reason</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            {...register("title", { required: true })}
            error={Boolean(errors.title)}
            helperText={errors.title && "This field is required"}
          />
          <TextField
            margin="dense"
            label="Body"
            type="text"
            fullWidth
            variant="outlined"
            {...register("body", { required: true })}
            error={Boolean(errors.body)}
            helperText={errors.body && "This field is required"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
