import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  isSameMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import React, { useState } from "react";

const CalendarForProjectTimeLine = ({
  transformedData,
  icon,
  addEvent,
  editEvent,
}) => {
  console.log(transformedData, "..");
  const [currentDate, setCurrentDate] = useState(new Date());

  const handlePrevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const nextMonthName = format(addMonths(currentDate, 1), "MMMM yyyy");
  const prevMonthName = format(subMonths(currentDate, 1), "MMMM yyyy");

  const isDateInRange = (date, ranges) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    return ranges?.some(
      (range) =>
        formattedDate >= range.startDate && formattedDate <= range.endDate
    );
  };

  function getDescriptionForDate(date, transformedData) {
    const formattedDate = format(date, "yyyy-MM-dd");
    const matchingData = transformedData?.find(
      (range) =>
        formattedDate >= range.startDate && formattedDate <= range.endDate
    );

    return matchingData ? matchingData.description : "";
  }

  function getId(date, transformedData) {
    const formattedDate = format(date, "yyyy-MM-dd");
    const matchingData = transformedData?.find(
      (range) =>
        formattedDate >= range.startDate && formattedDate <= range.endDate
    );

    return matchingData ? matchingData.id : "";
  }

  return (
    <div className="calendar-container">
      <div className="calendar_2">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="header-text">Calendar</div>
          <div className="event_icon" onClick={addEvent}>
            {icon}
          </div>
        </div>
        <div className="nav-buttons">
          <div className="nav-button" onClick={handlePrevMonth}>
            &lt; {prevMonthName}
          </div>
          <div>{format(currentDate, "MMMM yyyy")}</div>
          <div className="nav-button" onClick={handleNextMonth}>
            {nextMonthName} &gt;
          </div>
        </div>
        <div className="days_2">
          {daysInMonth?.map((day) => {
            const isInRange = isDateInRange(day, transformedData);

            return (
              <div
                key={day.toISOString()}
                className={`day_2 ${
                  isSameMonth(day, currentDate) ? "current-month" : ""
                } `}
                title={getDescriptionForDate(day, transformedData)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="day-of-week">{format(day, "E")}</div>
                  <div className="day-number">{format(day, "d")}</div>
                </div>
                {isInRange
                  ? transformedData
                      .filter(
                        (range) => getId(day, transformedData) === range.id
                      )
                      .map((range, index) => (
                        <hr
                          key={`${day.toISOString()}-${index}`}
                          onClick={(e) => {
                            console.log(range.id);
                            editEvent(range.id);
                          }}
                          className="leave-date-hr"
                          style={{
                            borderTopColor: range.color,
                          }}
                        ></hr>
                        
                      ))
                  : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CalendarForProjectTimeLine;
