// import axios from "";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { baseUrl } from "../../config/baseUrl";
import { useEffect, useState } from "react";
import { Eye } from "lucide-react";
import { DataTable } from "../../components/table/Data-Table";
import moment from "moment";

export function TeamMemberDetails({  data,team,trigger }) {
 

  const columns = [
    { header: "S.No", cell: ({ row }) => row.index + 1 },
    { header: "Name of the Employee", cell: ({ row }) => row.original.name },
    { header: "EmployeeId ", cell: ({ row }) => row.original.employeeId },
    {
      header: "Date of Joining ",
      cell: ({ row }) => moment(row.original.dateOfJoining).format("DD-YY-MM"),
    },
    { header: "Email Id ", cell: ({ row }) => row.original.email },
    { header: "Designation ", cell: ({ row }) => row.original.designation?.name },
  ];

  return (
    <Dialog  className='min-w-[800px] max-h-[400px] overflow-auto'>
      <DialogTrigger asChild>
        <div className="w-full flex items-center justify-center ">
          <span className="cursor-pointer text-[blue] hover:underline ">{trigger}</span>
        </div>
      </DialogTrigger>
      <DialogContent className='min-w-[800px] max-h-[400px] overflow-auto'>
        <DataTable
          columns={columns}
          data={data||[]}
          defaultTheme={true}
          heading={`Members Detail in ${team}`}
          TableHeight={"250px"}
        />
      </DialogContent>
    </Dialog>
  );
}
