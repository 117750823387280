import React from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Textarea } from "../../components/ui/textarea";
import useAddComment from "./reportCommentMutation";
export function TableModal({ comments, reportId, isAdmin }) {
  const { deleteCommentCommanMutation } = useAddComment();

  return (
    <>
      <Dialog>
        <DialogTrigger>
          <button className="button-17 !rounded-[10px]">View</button>
        </DialogTrigger>

        <DialogContent>
          <table
            style={{
              width: "100%",
            }}
          >
            <thead
              style={{
                color: "#c0c3c7",
                fontWeight: "bold",
              }}
            >
              <tr
                style={{
                  backgroundColor: "#f1f4f7",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                <th>S.No.</th>
                <th>Comment</th>
                {isAdmin ? null : (
                  <>
                    <td>EmployeeId</td>
                    <td>Name</td>
                    <td>Designation</td>
                  </>
                )}

                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {comments.map((comment, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{comment.comment}</td>

                  {isAdmin ? null : (
                    <>
                      <td>{comment?.commentBy?.employeeId}</td>

                      <td>{comment?.commentBy?.name}</td>
                      <td>{comment?.commentBy?.designation?.name}</td>
                    </>
                  )}

                  <>
                    <td>
                      <EditCommentModal
                        comment={comment.comment}
                        reportId={reportId}
                        commentId={comment._id}
                      />
                    </td>
                    <td>
                      <button
                        onClick={(e) => {
                          const commentData = {
                            reportId: reportId,
                            commentId: comment._id,
                          };

                          deleteCommentCommanMutation.mutate(commentData);
                        }}
                        style={{
                          backgroundColor: "#CD5C5C",
                          color: "white",
                          padding: "8px 16px",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    </>
  );
}

const EditCommentModal = ({ comment, reportId, commentId }) => {
  const [commentData, setCommentData] = React.useState(comment);
  const { editCommentCommonMutation } = useAddComment();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (commentData === "") {
      return;
    }

    const data = {
      comment: commentData,
      id: reportId,
      commentId,
    };

    editCommentCommonMutation.mutate(data);
  };

  return (
    <Dialog>
      <DialogTrigger>
        <button
          style={{
            backgroundColor: "#085394",
            color: "white",
            padding: "8px 16px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          Edit
        </button>
      </DialogTrigger>

      <DialogContent>
        <form onSubmit={handleSubmit} className="grid gap-3">
          <DialogHeader>Edit Comment</DialogHeader>
          <Textarea
            value={commentData}
            onChange={(e) => setCommentData(e.target.value)}
            className="resize-none"
            placeholder="Add a comment"
          ></Textarea>
          <DialogFooter>
            <DialogClose asChild>
              <button type="submit" className="button-17">
                Save
              </button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
