/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { AddModal } from "../Client/AddModal";

const index = () => {
  const [task, setTask] = useState("");




  return (
    <>
      <div className="grid gap-3 bg-white rounded-lg">
        <div className="flex justify-end">
          <AddModal />

          <table>
            <thead>
              <tr>
                <th>Task</th>
                <th>EmployeeId</th>
                <th>ProjectId</th>
                <th>Status</th>
                <th>StartDate</th>
                <th>Description</th>
              </tr>
            </thead>

            <tbody>
              {task && task?.length > 0 ? (
                task?.map((rowdata, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{rowdata.vivek}</td>
                    <td>{rowdata.vivek}</td>
                    <td>{rowdata.vivek}</td>
                    <td>{rowdata.vivek}</td>
                    <td>{rowdata.vivek}</td>
                
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default index;
