import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Textarea } from "../../components/ui/textarea";
import { Eye } from "lucide-react";

const Reportdetailedview = ({ report }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Eye style={{ margin: "auto" }} />
      </DialogTrigger>
      <DialogContent className="!min-w-[60%] !min-h-[50%] max-h-[700px] max-w-[800px] overflow-auto">
        <div className="w-full h-full pointer-events-none overflow">
         {report?.map((item, index) => (
            <div key={index} clasName='w-full p-2 gap-1'>
              <div className="text-xl font-bold text-[black] break-words">{item.project?.projectName}</div>
              <div className="text-xl font-normal text-[black] break-words w-full p-2">{item.report}</div>
            </div>
          ))} 
          {/* <Textarea value={report} readOnly className='w-full h-full pointer-events-none'/> */}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Reportdetailedview;
