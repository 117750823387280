import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { Select } from "@radix-ui/react-select";
import React from "react";
import { IoPersonAddSharp } from "react-icons/io5";
import Modal from "react-modal";
const styles = {
  width: "100%",
  height: "75%",
  overflow: "auto",
};
const ModalContainer = {
  width: "100%",
  height: "calc(100% - 150px)",
  overflow: "auto",
};
const modalHeader = {
  width: "100%",
  height: "75px",
  display: "flex",
  flexDirection: "column",
};
export function InvitePeopleToCallModal(props) {
  const {
    showAddPeoplePopup,
    closeAddPeoplePopup,
    Allemployees,
    InvitePeopleTocall,
    setSearchInput,
    searchInputValue,
    invitedPeople,
    handleSubmit
    
  } = props;

const handleSearch=(e)=>{
    setSearchInput(e.target.value)
}
  
  return (
    <Modal
      isOpen={showAddPeoplePopup}
      onRequestClose={closeAddPeoplePopup}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "450px",
          height: "450px",
          borderRadius: "10px",
          overflow:'hidden'
        },
      }}
      contentLabel="Add People"
    >
      <div style={modalHeader}>
        <div>
          <p style={{ fontSize: "20px", fontWeight: "600" }}>
            Add People
            <span
              style={{
                float: "right",
                cursor: "pointer",
              }}
              onClick={closeAddPeoplePopup}
            >
              X
            </span>
          </p>
        </div>
        <div
          style={{
            width: "100%",
            height: "70%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
          onClick={handleSubmit}
        >
          
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#085394",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoPersonAddSharp />
              <span style={{ marginLeft: "10px" }}> Invite People</span>
            </p>
          
        </div>
      </div>
      <div style={{ width: "100%" ,height:'75px'}}>
        <TextField
          fullWidth
          label="Search Employee to give invite"
          variant="standard"
          value={searchInputValue}
          onChange={handleSearch}
        />
      </div>
      <div style={ModalContainer}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
          onSubmit={props?.handleSubmit}
        >
          <div style={styles}>
            <>
              <List>
                {Allemployees?.map((employee) => (
                  <ListItem
                    onClick={() => {
                      InvitePeopleTocall(employee);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>{employee.name[0]}</Avatar>
                    </ListItemAvatar>
                    <ListItemText id={employee.name} primary={employee.name} />
                    <ListItemIcon>
                      <Checkbox
                        edge="end"
                        checked={!!invitedPeople.find((item) => item.id === employee._id)}                        tabIndex={-1}
                        disableRipple
                        //   inputProps={{ 'aria-labelledby':  }}
                      />
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </>
          </div>
        </form>
      </div>
    </Modal>
  );
}
