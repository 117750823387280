import { Delete, Trash } from "lucide-react";
import moment from "moment";
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import ReactSelect from "react-select";
import useProjectHook from "../../hooks/useProjectHook";
import { useParams } from "react-router-dom";
import { SelectCustomStyles } from "../../../utils/styles";
import { IoIosRemoveCircle } from "react-icons/io";

export default ({
  nestIndex,
  removeTeam,
  control,
  project,
  errors,
  register,
  employeeOptions,
  modules,
  watch,
  setValue,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `teams.${nestIndex}.members`,
  });
  const { updateTeamMutation } = useProjectHook();
  const params = useParams();
  const id = params.projectId;
  const deleteTeam = async (teamIndex, employeeIndex,deleteTeam) => {
    let data;
    if(deleteTeam){
       data = {
        projectId: id,
        teamId: watch(`teams.${teamIndex}.teamType`),
      };
    }else{
      data = {
        projectId: id,
        teamId: watch(`teams.${teamIndex}.teamType`),
        employeeId: watch(`teams.${teamIndex}.members.${employeeIndex}.employeeId`),
      };
    }

    try {
      const response = updateTeamMutation.mutateAsync(data);
      remove(employeeIndex);
      if (deleteTeam) {
        removeTeam(teamIndex);
      }
    } catch (err) {
      throw new Error(err);
    }
  };
  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className="form-row">
            <div className="form-row">
              <Controller
                name={`teams.${nestIndex}.members.${k}.employeeId`}
                control={control}
                render={({ field }) => {
                  const selectedOption = employeeOptions?.find(
                    (emp) => emp.value === field.value
                  );

                  return (
                    <ReactSelect
                      {...field}
                      styles={SelectCustomStyles}
                      isClearable
                      options={employeeOptions}
                      value={
                        selectedOption
                          ? {
                              value: selectedOption.value,
                              label: selectedOption.label,
                            }
                          : null
                      }
                      placeholder={"Select an Employee ...."}
                      onChange={(option) =>
                        field.onChange(option ? option.value : null)
                      } // Directly pass the value
                    />
                  );
                }}
              />
            </div>
            <div className="form-group">
              <Controller
                name={`teams.${nestIndex}.members.${k}.modules`}
                control={control}
                render={({ field }) => {
                  const selectedOptions =
                    field?.value?.map((mod) => ({
                      value: mod,
                      label: mod,
                    })) || [];
                  return (
                    <ReactSelect
                      {...field}
                      styles={SelectCustomStyles}
                      isClearable
                      isMulti
                      options={modules}
                      value={selectedOptions}
                      placeholder={"Select modules ...."}
                      onChange={(selected) => {
                        const values = selected
                          ? selected.map((opt) => opt?.value)
                          : [];
                        field.onChange(values);
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="StartDate">
      Start Date:
   </label> */}
              <input
                type="date"
                max={moment(project?.projectEndDate).format("YYYY-MM-DD")}
                min={moment(project?.projectStartDate).format("YYYY-MM-DD")}
                id={`teams.${nestIndex}.members.${k}.startDate`}
                name={`teams.${nestIndex}.members.${k}.startDate`}
                value={watch(`teams.${nestIndex}.members.${k}.startDate`)}
                onChange={(e) =>
                  setValue(
                    `teams.${nestIndex}.members.${k}.startDate`,
                    e.target.value
                  )
                }
                {...register(`teams.${nestIndex}.members.${k}.startDate`, {
                  //    validate: validateManagerTeamMemberDate,
                })}
              />

              {errors.managerTeamMemberStartDate && (
                <p className="error">
                  {errors.managerTeamMemberStartDate.message}
                </p>
              )}
            </div>

            <div className="form-group">
              {/* <label htmlFor="EndDate">
    End Date:
   </label> */}
              <input
                type="date"
                max={moment(project?.projectEndDate).format("YYYY-MM-DD")}
                min={watch(`teams.${nestIndex}.members.${k}.startDate`)}
                id={`teams.${nestIndex}.members.${k}.endDate`}
                name={`teams.${nestIndex}.members.${k}.endDate`}
                value={watch(`teams.${nestIndex}.members.${k}.endDate`)}
                onChange={(e) =>
                  setValue(
                    `teams.${nestIndex}.members.${k}.endDate`,
                    e.target.value
                  )
                }
                {...register(`teams.${nestIndex}.members.${k}.endDate`, {})}
              />

              {errors.managerTeamMemberEndDate && (
                <p className="error">
                  {errors.managerTeamMemberEndDate.message}
                </p>
              )}
            </div>

            <button type="button" onClick={() => deleteTeam(nestIndex, k)}>
              <IoIosRemoveCircle  color={"red"} size={'20px'} style={{fontSize:'20px'}}/>
            </button>
          </div>
        );
      })}
<div className="flex gap-4 mb-[10px]">

      <button type="button" onClick={() => append({})} className="flex items-center justify-center gap-2">
        <div className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]" style={{backgroundColor:'rgb(8,83,148,0.3)'}}>+</div> employee
      </button>
      <button type="button" onClick={() => deleteTeam(nestIndex,'',true)}  className="flex items-center justify-center gap-2">
      <div className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff] text-[14px]" style={{backgroundColor:' rgb(255,0,0,0.4)'}}>-</div> Team
              </button>
</div>

      {/* <hr /> */}
    </div>
  );
};
const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
    minWidth:'200px'
  }),
};
