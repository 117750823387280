import { ScreenShare } from "lucide-react";
import Client from "./admin/pages/Client/Client";
import { AdminDashboard } from "./admin/pages/Dashboard/AdminDashboard";
import DetailedtimeSheet from "./admin/pages/DetailedTimesheet/Detailedtimesheet";
import { FollowUps } from "./admin/pages/FollowUps/FollowUps.jsx";
import Hiring from "./admin/pages/Hiring/Hiring";
import Leads from "./admin/pages/Leads/Leads.jsx";
import Leaves from "./admin/pages/Leaves/Leave";
import ProjectCalender from "./admin/pages/ProjectCalender/ProjectCalender.jsx";
import Responsibility from "./admin/pages/Responsibilty/Responsibilty.jsx";
import Testing from "./admin/pages/Testers/Testing.jsx";
import EmployeeAdd from "./admin/pages/addProject/addEmployee.jsx";
import AddProjectModal from "./admin/pages/addProject/addProjects.jsx";
import Attendance from "./admin/pages/attendance/Attendace";
import EditProject from "./admin/pages/editProjects/EditProject.jsx";
// import Employee from "./admin/pages/employee/Employee";
import Payroll from "./admin/pages/payroll/Payroll";
import Profile from "./admin/pages/profile/Profile";
import Timeline from "./admin/pages/projectTimeline/Timeline.jsx";
import Project from "./admin/pages/projects/Project";
import ProjectDetails from "./admin/pages/projects/ProjectDetails";
import { AdminReport } from "./admin/pages/reports/AdminReport";
import ListNotification from "./admin/pages/sendNotification/ListNotification";
import SendNotification from "./admin/pages/sendNotification/SendNotification";
import TaskManagement from "./admin/pages/taskmangement/TaskManagement";
import Team from "./admin/pages/team/Team";
import Screens from "./admin/pages/screens/Screens.jsx";
import AddTask from "./admin/pages/AddTask/index.js";
import OfficeCalendar from "./admin/components/newCalender/NewCalender.js";
import Meeting from "./admin/Meeting/page.js";
import Meet from "./admin/pages/MeetingTab/index.jsx";
import DetailedrojectTesterSheet from "./admin/pages/Testers/DetailedrojectTesterSheet.jsx";
import PresentEmployees from "./admin/pages/Dashboard/PresentEmployees.jsx";
import AbsentEmployees from "./admin/pages/Dashboard/AbsentEmployees.jsx";
import EmployeeListByWorkMode from "./admin/pages/Dashboard/employeeListByWorkMode.jsx";
import MonthlyAttendence from "../src/admin/pages/attendance/MonthlyAttendence.jsx"
import CompletedProjects from "./admin/pages/Dashboard/CompletedProjects.jsx";
import Report from "./admin/pages/reports/Report.jsx";
import EmployeLeaveList from "./admin/pages/Leaves/EmployeLeaveList.jsx";
import ReportById from "./admin/pages/reports/ReportById.jsx";
import { LeaveTable } from "./admin/components/table/LeaveTable.jsx";
import HolidayTable from "./admin/components/table/HolidayTable.jsx";
import BirthdayTable from "./admin/components/table/BirthdayTable.jsx";
import TasksByUserId from "./admin/pages/projects/TasksByUserId.jsx";
import EditProjectNew from "./admin/pages/editProjects/EditProjectNew.jsx";
import AddProjectNew from "./admin/pages/addProject/addProjectNew.jsx";
import EmployeeWiseProjects from "./admin/pages/team/EmployeeWiseProjects.jsx";
import Mail from "./admin/pages/Mails/mail.jsx";
import DeletedUsers from "./admin/pages/team/DeletedUsers.jsx";

export const routes = [
  {
    path: "/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/clients",
    component: Client,
  },

  {
    path: "/taskManagement",
    component: TaskManagement,
  },
  {
    path: "/projectTimeline",
    component: Timeline,
  },
  {
    path: "/employeeProjects/:id",
    component: EmployeeWiseProjects,
  },
  {
    path: "/payroll",
    component: Payroll,
  },
  {
    path: "/team",
    component: Team,
  },
  {
    path: "/detailedTimesheet",
    component: OfficeCalendar,
  },

  {
    path: "/projectTickets/:id",
    component: DetailedrojectTesterSheet,
  },
  {
    path: "/attendance",
    component: Attendance,
  },
  { path: "/presentEmployees", component: PresentEmployees },
  { path: "/absentEmployees", component: AbsentEmployees },
  {
    path: "/project",
    component: Project,
  },
  {
    path: "/addProject",
    component: AddProjectNew,
  },
{path:'/dashboard-leave',
  component: LeaveTable
},
{path:'/dashboard-holiday',
  component: HolidayTable
},
{path:'/dashboard-birthday',
  component: BirthdayTable
},
{path:'/mails',
  component: Mail
},
{path:'/deletedUsers',
  component: DeletedUsers
},
  {
    path: "/addEmployee",
    component: EmployeeAdd,
  },

  {
    path: "/testing",
    component: Testing,
  },

  {
    path: "/editProject/:projectId",
    component:EditProjectNew ,
    // component:EditProject
  },
  {
    path: "/workMode",
    component: EmployeeListByWorkMode,
  },

  {
    path: "/ProjectDetails/:projectId",
    component: ProjectDetails,
  },
  {
    path: "/newHiring",
    component: Hiring,
  },
  {
    path: "/task/:id",
    component: TasksByUserId,
  },
  {
    path: "/sendnotification",
    component: SendNotification,
  },
  {
    path: "/listNotification",
    component: ListNotification,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/leave",
    component: Leaves,
  },
  {
    path: "/employeeLeaveList/:id",
    component: EmployeLeaveList,
  },
  {
    path: "/monthlyReports/:id",
    component: ReportById,
  },
   {
      path: "/adminReport",
   
      component: AdminReport,
    },

  {
    path: "/responsibility",
    component: Responsibility,
  },
  {
    path: "/Hiring-followups",
    component: FollowUps,
  },
  {
    path: "/leads",
    component: Leads,
  },

  {
    path: "/projectCalendar",
    component: ProjectCalender,
  },
  {
    path: "/addTask",
    component: AddTask,
  },
  {
    path: "/Screens",
    component: Screens,
  },
  {
    path: "/meeting",
    component: Meeting,
  },
  {
    path: "/meet/:id",
    component: Meet,
  },
  {
    path:"/monthlyAttendence/:id",
    component:MonthlyAttendence,
  },
  {
    path:'/completedProjects',
    component:CompletedProjects
  }
];
