import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import useAuthHook from "../../hooks/authHook";
import { handleKeyDown } from "../../../utils/lib";

export function AdminLogin() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const { loginAdminMutation } = useAuthHook();

  const onSubmit = (data) => {
    loginAdminMutation.mutate(data);
  };

  return (
    <form
      autoComplete="off"
      autoCorrect="off"
      onSubmit={handleSubmit(onSubmit)}
      className="login-form"
    >
      <div className="form-container">
        <div className="input-container">
          <label>Email:</label>
          <Input
            type="text"
            onKeyDown={handleKeyDown}
            placeholder="Enter your email"
            {...register("adminEmail", { required: true })}
          />
          {errors.adminEmail && (
            <span className="error-message">{errors.adminEmail.message}</span>
          )}
        </div>
        <div className="input-container">
          <label>Password:</label>
          <div className="relative">
            <Input
             onKeyDown={handleKeyDown}
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              {...register("adminPassword", { required: true })}
            />
            <div
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {errors.adminPassword && (
            <span className="error-message">
              {errors.adminPassword.message}
            </span>
          )}
        </div>
        <div className="forgot-password">
          <p>Forgot Password? </p>
          <Link to="/forgetPass">
            <p>Click Here</p>
          </Link>
        </div>
      </div>
      <div className="button-container">
        <Button
          disabled={loginAdminMutation.isLoading || isSubmitting}
          type="submit"
        >
          {isSubmitting ? "Logging in..." : "Login"}
        </Button>
      </div>
    </form>
  );
}
