import { toast } from "react-toastify";
import { baseUrl } from "../../config/baseUrl";
import axios from "./../../config/axiosAuth";

const followUpServices = {
  getFollowUps: async () => {
    const result = await axios.post(baseUrl + "/getAllFollowup", {});
    console.log(result);
    return result.data.result;
  },

  addFollowUp: async (data) => {
    try {
      const result = await axios.post(baseUrl + "/addFollowup", data);

      // toast.success(result.data.message);
      return result.data;
    } catch (error) {
      console.log(error.response.data.message);
      // toast.error(error.response.data.message);
    }
  },

  editFollowUp: async (id, data) => {
    try {
      const result = await axios.post(baseUrl + `/editFollowup/${id}`, data);
      // toast.success(result.data.message);
      return result.data;
    } catch (error) {
      console.log(error.response.data.message);
      // toast.error(error.response.data.message);
    }
  },

  deleteFollowUp: async (id) => {
    // const result = await axios.post(baseUrl + `/deleteFollowUp/${id}`);
    // return result.data;

    try {
      const result = await axios.post(baseUrl + `/deleteFollowup/${id}`);
      // toast.success(result.data.message);
      return result.data;
    } catch (error) {
      console.log(error.response.data.message);
      // toast.error(error.response.data.message);
    }
  },
};

export default followUpServices;
