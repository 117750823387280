import React from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/ui/button";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import useProjectHook from "../../hooks/useProjectHook";

const EmployeeAdd = () => {
  let [searchParams] = useSearchParams();

  const projectId = searchParams.get("projectId");

  const { addEmployeesInProject } = useProjectHook();
  const { data: employees } = useEmployeeHook();
  const { register, handleSubmit, setValue, watch, formState } = useForm({
    defaultValues: {
      // projectName: "",
      backendLeaderId: "",
      backendLeaderStartDate: "",
      backendLeaderEndDate: "",
      frontendLeaderId: "",
      frontendLeaderStartDate: "",
      frontendLeaderEndDate: "",
      uiUxLeaderId: "",
      uiUxLeaderStartDate: "",
      uiUxLeaderEndDate: "",
      managerId: "",
      employeeIds: [],
      employeesData: [],
      // projectStartDate: "",
      // projectDescription: "",
      // projectEndDate: "",
    },
  });

  const { errors } = formState;

  const selectedEmployeeId = watch("selectedEmployeeId");
  const employeeStartDate = watch("employeeStartDate");
  const employeeEndDate = watch("employeeEndDate");

  const onSubmit = (data) => {
    const addData = {
      // projectName: data.projectName,
      backendLeader: {
        leaderId: data.backendLeaderId,
        start_date: data.backendLeaderStartDate,
        end_date: data.backendLeaderEndDate,
        // task: data.backendLeaderTask,
      },
      frontendLeader: {
        leaderId: data.frontendLeaderId,
        start_date: data.frontendLeaderStartDate,
        end_date: data.frontendLeaderEndDate,
        // task: data.frontendLeaderTask,
      },
      uiUxLeader: {
        leaderId: data.uiUxLeaderId,
        start_date: data.uiUxLeaderStartDate,
        end_date: data.uiUxLeaderEndDate,
        // task: data.uiUxLeaderTask,
      },
      employeesTimeLine: data.employeesData,

      managerId: data.managerId,
      employeeId: data.employeeIds,
      projectId,
      // projectStartDate: data.projectStartDate,
      // projectDescription: data.projectDescription,
      // projectEndDate: data.projectEndDate,
    };

    addEmployeesInProject.mutate(addData);
  };

  const handleAddEmployeeWithDate = () => {
    if (selectedEmployeeId && employeeStartDate && employeeEndDate) {
      setValue("employeeIds", [...watch("employeeIds"), selectedEmployeeId]);
      setValue("employeesData", [
        ...watch("employeesData"),
        {
          employeeId: selectedEmployeeId,
          startDate: employeeStartDate,
          endDate: employeeEndDate,
        },
      ]);

      // toast.success("Employee Added Successfully");
      setValue("selectedEmployeeId", "");
      setValue("employeeStartDate", "");
      setValue("employeeEndDate", "");
    } else {
      toast.error("Please select employee and date");
    }
  };

  const validateStartAndEndDate = () => {
    const startDate = new Date(watch("projectStartDate"));
    const endDate = new Date(watch("projectEndDate"));

    return startDate < endDate || "Start date must be lower than end date";
  };

  const validateFrontendLeaderStartAndEndDate = () => {
    const startDate = new Date(watch("frontendLeaderStartDate"));
    const endDate = new Date(watch("frontendLeaderEndDate"));

    return startDate < endDate || "Start date must be lower than end date";
  };

  const validateBackendLeaderStartAndEndDate = () => {
    const startDate = new Date(watch("backendLeaderStartDate"));
    const endDate = new Date(watch("backendLeaderEndDate"));

    return startDate < endDate || "Start date must be lower than end date";
  };

  const validateUiUxLeaderStartAndEndDate = () => {
    const startDate = new Date(watch("uiUxLeaderStartDate"));
    const endDate = new Date(watch("uiUxLeaderEndDate"));

    return startDate < endDate || "Start date must be lower than end date";
  };

  const validateEmployeeStartAndEndDate = () => {
    const startDate = new Date(watch("employeeStartDate"));
    const endDate = new Date(watch("employeeEndDate"));

    if (selectedEmployeeId && employeeStartDate && employeeEndDate) {
      return startDate < endDate || "Start date must be lower than end date";
    }

    return null;
  };

  return (
    <form
      style={{
        overflowY: "scroll",
        padding: "20px",
      }}
      className="bg-white rounded-lg "
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-row">
        {/* <div className="form-group">
          <input
            placeholder="Project Name"
            className="px-1"
            type="text"
            {...register("projectName", {
              required: "Please enter a project name",

              validate: (value) =>
                value.trim() !== "" || "Input cannot be only whitespaces",

              maxLength: {
                value: 15,
                message: "Maximum 15 characters allowed",
              },
              minLength: {
                value: 3,
                message: "Minimum 3 characters allowed",
              },
            })}
          />

          {errors.projectName && (
            <p className="text-xs text-red-500  ">
              {errors.projectName.message}
            </p>
          )}
        </div> */}

        <div className="form-group">
          <select
            id="managerId"
            name="managerId"
            value={watch("managerId")}
            onChange={(e) => setValue("managerId", e.target.value)}
            {...register("managerId", {
              required: "Please select a manager",
            })}
          >
            <option value="" className="text-slate-300">
              Select Manager
            </option>
            {employees?.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.name}
              </option>
            ))}
          </select>
          {errors.managerId && (
            <p className="text-xs text-red-500  ">{errors.managerId.message}</p>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <select
            id="backendLeaderId"
            name="backendLeaderId"
            value={watch("backendLeaderId")}
            onChange={(e) => setValue("backendLeaderId", e.target.value)}
            {...register("backendLeaderId", {
              required: "Please select a backend leader",
            })}
          >
            <option value="">Select Backend Leader</option>
            {employees?.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.name}
              </option>
            ))}
          </select>
          {errors.backendLeaderId && (
            <p className="text-xs text-red-500  ">
              {errors.backendLeaderId.message}
            </p>
          )}
        </div>

        <div className="form-group">
          <input
            type="date"
            id="backendLeaderStartDate"
            name="backendLeaderStartDate"
            value={watch("backendLeaderStartDate")}
            onChange={(e) => setValue("backendLeaderStartDate", e.target.value)}
            {...register("backendLeaderStartDate", {
              required: "Please select a backend leader start date",
            })}
          />
          <span className="text-[9px] text-slate-400 ">
            Select Backend Leader Start Date*
          </span>

          {errors.backendLeaderStartDate && (
            <p className="text-xs text-red-500  ">
              {errors.backendLeaderStartDate.message}
            </p>
          )}
        </div>

        <div className="form-group">
          <input
            type="date"
            id="backendLeaderEndDate"
            name="backendLeaderEndDate"
            value={watch("backendLeaderEndDate")}
            onChange={(e) => setValue("backendLeaderEndDate", e.target.value)}
            {...register("backendLeaderEndDate", {
              required: "Please select a backend leader end date",
              validate: validateBackendLeaderStartAndEndDate,
            })}
          />
          <span className="text-[9px] text-slate-400 ">
            Select Backend Leader End Date*
          </span>

          {errors.backendLeaderEndDate && (
            <p className="text-xs text-red-500  ">
              {errors.backendLeaderEndDate.message}
            </p>
          )}
        </div>

        {/* <div className="form-group">
          <input
            type="text"
            id="backendLeaderTask"
            name="backendLeaderTask"
            placeholder="Backend Leader Task"
            value={watch("backendLeaderTask")}
            onChange={(e) => setValue("backendLeaderTask", e.target.value)}
            {...register("backendLeaderTask", {
              required: "Please enter a backend leader task",

              minLength: {
                value: 3,
                message: "Minimum 3 characters allowed",
              },

              validate: (value) =>
                value.trim() !== "" || "Input cannot be only whitespaces",

              maxLength: {
                value: 25,
                message: "Maximum 25 characters allowed",
              },
            })}
          />

          {errors.backendLeaderTask && (
            <p className="text-xs text-red-500  ">
              {errors.backendLeaderTask.message}
            </p>
          )}
        </div> */}
      </div>

      <div className="form-row">
        <div className="form-group">
          <select
            id="frontendLeaderId"
            name="frontendLeaderId"
            value={watch("frontendLeaderId")}
            onChange={(e) => setValue("frontendLeaderId", e.target.value)}
            {...register("frontendLeaderId", {
              required: "Please select a frontend leader",
            })}
          >
            <option value="">Select Frontend Leader</option>
            {employees?.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.name}
              </option>
            ))}
          </select>

          {errors.frontendLeaderId && (
            <p className="text-xs text-red-500  ">
              {errors.frontendLeaderId.message}
            </p>
          )}
        </div>

        <div className="form-group">
          <input
            type="date"
            id="frontendLeaderStartDate"
            name="frontendLeaderStartDate"
            value={watch("frontendLeaderStartDate")}
            onChange={(e) =>
              setValue("frontendLeaderStartDate", e.target.value)
            }
            {...register("frontendLeaderStartDate", {
              required: "Please select a frontend leader start date",
            })}
          />

          <span className="text-[9px] text-slate-400 ">
            Select Frontend Leader Start Date*
          </span>

          {errors.frontendLeaderStartDate && (
            <p className="text-xs text-red-500  ">
              {errors.frontendLeaderStartDate.message}
            </p>
          )}
        </div>

        <div className="form-group">
          <input
            type="date"
            id="frontendLeaderEndDate"
            name="frontendLeaderEndDate"
            value={watch("frontendLeaderEndDate")}
            onChange={(e) => setValue("frontendLeaderEndDate", e.target.value)}
            {...register("frontendLeaderEndDate", {
              required: "Please select a frontend leader end date",
              validate: validateFrontendLeaderStartAndEndDate,
            })}
          />

          <span className="text-[9px] text-slate-400 ">
            Select Frontend Leader End Date*
          </span>

          {errors.frontendLeaderEndDate && (
            <p className="text-xs text-red-500  ">
              {errors.frontendLeaderEndDate.message}
            </p>
          )}
        </div>
        {/* 
        <div className="form-group">
          <input
            type="text"
            id="frontendLeaderTask"
            placeholder="Frontend Leader Task"
            name="frontendLeaderTask"
            value={watch("frontendLeaderTask")}
            onChange={(e) => setValue("frontendLeaderTask", e.target.value)}
            {...register("frontendLeaderTask", {
              required: "Please enter a frontend leader task",

              maxLength: {
                value: 25,
                message: "Maximum 25 characters allowed",
              },

              minLength: {
                value: 3,
                message: "Minimum 3 characters allowed",
              },

              validate: (value) =>
                value.trim() !== "" || "Input cannot be only whitespaces",
            })}
          />

          {errors.frontendLeaderTask && (
            <p className="text-xs text-red-500  ">
              {errors.frontendLeaderTask.message}
            </p>
          )}
        </div> */}
      </div>

      <div className="form-row">
        <div className="form-group">
          <select
            id="uiUxLeaderId"
            name="uiUxLeaderId"
            value={watch("uiUxLeaderId")}
            onChange={(e) => setValue("uiUxLeaderId", e.target.value)}
            {...register("uiUxLeaderId", {
              required: "Please select a UI/UX leader",
            })}
          >
            <option value="">Select UI/UX Leader</option>
            {employees?.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.name}
              </option>
            ))}
          </select>

          {errors.uiUxLeaderId && (
            <p className="text-xs text-red-500  ">
              {errors.uiUxLeaderId.message}
            </p>
          )}
        </div>

        <div className="form-group">
          <input
            type="date"
            id="uiUxLeaderStartDate"
            name="uiUxLeaderStartDate"
            value={watch("uiUxLeaderStartDate")}
            onChange={(e) => setValue("uiUxLeaderStartDate", e.target.value)}
            {...register("uiUxLeaderStartDate", {
              required: "Please select a UI/UX leader start date",
            })}
          />

          <span className="text-[9px] text-slate-400 ">
            Select UI/UX Leader Start Date*
          </span>

          {errors.uiUxLeaderStartDate && (
            <p className="text-xs text-red-500  ">
              {errors.uiUxLeaderStartDate.message}
            </p>
          )}
        </div>

        <div className="form-group">
          <input
            type="date"
            id="uiUxLeaderEndDate"
            name="uiUxLeaderEndDate"
            value={watch("uiUxLeaderEndDate")}
            onChange={(e) => setValue("uiUxLeaderEndDate", e.target.value)}
            {...register("uiUxLeaderEndDate", {
              required: "Please select a UI/UX leader end date",
              validate: validateUiUxLeaderStartAndEndDate,
            })}
          />

          <span className="text-[9px] text-slate-400 ">
            Select UI/UX Leader End Date*
          </span>

          {errors.uiUxLeaderEndDate && (
            <p className="text-xs text-red-500  ">
              {errors.uiUxLeaderEndDate.message}
            </p>
          )}
        </div>

        {/* <div className="form-group">
          <input
            type="text"
            placeholder="UI/UX Leader Task"
            id="uiUxLeaderTask"
            name="uiUxLeaderTask"
            value={watch("uiUxLeaderTask")}
            onChange={(e) => setValue("uiUxLeaderTask", e.target.value)}
            {...register("uiUxLeaderTask", {
              required: "Please enter a UI/UX leader task",

              maxLength: {
                value: 25,
                message: "Maximum 25 characters allowed",
              },

              minLength: {
                value: 3,
                message: "Minimum 3 characters allowed",
              },

              validate: (value) =>
                value.trim() !== "" || "Input cannot be only whitespaces",
            })}
          />

          {errors.uiUxLeaderTask && (
            <p className="text-xs text-red-500  ">
              {errors.uiUxLeaderTask.message}
            </p>
          )}
        </div> */}
      </div>

      <div className="form-row">
        <div className="form-group">
          <select
            value={watch("selectedEmployeeId")}
            onChange={(e) => setValue("selectedEmployeeId", e.target.value)}
            {...register("selectedEmployeeId")}
          >
            <option value="">Select Employee</option>
            {employees?.map((employee) => (
              <option key={employee?._id} value={employee?._id}>
                {employee.name}
              </option>
            ))}
          </select>

          {errors.selectedEmployeeId && (
            <p className="text-xs text-red-500  ">
              {errors.selectedEmployeeId.message}
            </p>
          )}
        </div>

        <div className="form-group">
          <input
            type="date"
            name="startDate"
            value={watch("employeeStartDate")}
            onChange={(e) => setValue("employeeStartDate", e.target.value)}
            {...register("employeeStartDate")}
          />

          <span className="text-[9px] text-slate-400 ">
            Select Employee Start Date*
          </span>

          {errors.employeeStartDate && (
            <p className="text-xs text-red-500  ">
              {errors.employeeStartDate.message}
            </p>
          )}
        </div>
        <div className="form-group">
          <input
            type="date"
            name="endDate"
            value={watch("employeeEndDate")}
            onChange={(e) => setValue("employeeEndDate", e.target.value)}
            {...register("employeeEndDate", {
              validate: validateEmployeeStartAndEndDate,
            })}
          />

          <span className="text-[9px] text-slate-400 ">
            Select Employee End Date*
          </span>

          {errors.employeeEndDate && (
            <p className="text-xs text-red-500  ">
              {errors.employeeEndDate.message}
            </p>
          )}
        </div>
        {/* <div className="form-group">
          <input
            type="text"
            name="task"
            value={watch("employeeTask")}
            placeholder="Employee Task"
            className="px-1"
            onChange={(e) => setValue("employeeTask", e.target.value)}
            {...register("employeeTask", {
              maxLength: {
                value: 25,
                message: "Maximum 25 characters allowed",
              },

              minLength: {
                value: 3,
                message: "Minimum 3 characters allowed",
              },

              validate: (value) =>
                value.trim() !== "" || "Input cannot be only whitespaces",
            })}
          />

          {errors.employeeTask && (
            <p className="text-xs text-red-500  ">
              {errors.employeeTask.message}
            </p>
          )}
        </div> */}

        <Button
          disabled={
            !watch("selectedEmployeeId") &&
            !watch("employeeStartDate") &&
            !watch("employeeEndDate")
          }
          className="btn btn-primary"
          onClick={handleAddEmployeeWithDate}
        ></Button>
      </div>

      {/* <div className="form-row">
        <div className="form-group">
          <input
            type="date"
            id="projectStartDate"
            name="projectStartDate"
            value={watch("projectStartDate")}
            onChange={(e) => setValue("projectStartDate", e.target.value)}
            {...register("projectStartDate", {
              required: "Please select a project start date",
            })}
          />

          <span className="text-[9px] text-slate-400 ">
            Select Project Start Date*
          </span>

          {errors.projectStartDate && (
            <p className="text-xs text-red-500  ">
              {errors.projectStartDate.message}
            </p>
          )}
        </div>

        <div className="form-group">
          <input
            type="date"
            id="projectEndDate"
            name="projectEndDate"
            value={watch("projectEndDate")}
            onChange={(e) => setValue("projectEndDate", e.target.value)}
            {...register("projectEndDate", {
              required: "Please select a project end date",
              validate: validateStartAndEndDate,
            })}
          />

          <span className="text-[9px] text-slate-400 ">
            Select Project End Date*
          </span>

          {errors.projectEndDate && (
            <p className="text-xs text-red-500  ">
              {errors.projectEndDate.message}
            </p>
          )}
        </div>
      </div> */}

      {/* <div
        style={{
          width: "100%",
        }}
      >
        <textarea
          id="projectDescription"
          name="projectDescription"
          value={watch("projectDescription")}
          onChange={(e) => setValue("projectDescription", e.target.value)}
          {...register("projectDescription", {
            required: "Please enter a project description",

            maxLength: {
              value: 50,
              message: "Maximum 50 characters allowed",
            },

            minLength: {
              value: 10,
              message: "Minimum 10 characters allowed",
            },

            validate: (value) =>
              value.trim() !== "" || "Input cannot be only whitespaces",
          })}
          placeholder="Project Description"
          className="p-1"
        ></textarea>

        {errors.projectDescription && (
          <p className="text-xs text-red-500">
            {errors.projectDescription.message}
          </p>
        )}
      </div> */}

      <Button type="submit">Save</Button>
    </form>
  );
};

export default EmployeeAdd;
