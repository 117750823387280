import * as React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.scss";
import useNotificationHook from "../../hooks/notificationHooks";
import { baseUrl } from "../../config/baseUrl";
import axios from "./../../config/axiosAuth";
import useAdminHook from "../../hooks/adminHook";
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import BellNotification from "../../../Assets/images/notification icon.png";
import adminimg from "../../../Assets/images/admin image.png";
import { IoIosArrowDown } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import useScreenShareStore from "../../store/ScreenShare";
import LogoutAlert from "../ui/LogoutAlert";

const Navbar = () => {
  const{setCallStarted}=useScreenShareStore()
  const path = window.location.pathname;
  const formatPath = (path) => {
    let formattedPath = path.replace(/([A-Z])/g, " $1"); // Add space before capital letters
    formattedPath =
      formattedPath.charAt(0).toUpperCase() + formattedPath.slice(1); // Capitalize first letter
    return formattedPath.toUpperCase();
  };
  const formattedPath = formatPath(path.substring(1));
  const [notification, setnotification] = React.useState(null);

  const { adminNotification } = useNotificationHook();

  const { adminProfile } = useAdminHook();


  const openNotification = Boolean(notification);
  const handleClickNotification = async (event) => {
    setnotification(event.currentTarget);
    await axios.put(baseUrl + "/markRead", {});
  };

  const handleCloseNotification = () => {
    setnotification(null);
  };

  const [anchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const logoutbtn = () => {
    setCallStarted(false)
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="wrapperInner">
        <h3 className=" text-2xl font-semibold my-2 text-black">{formattedPath}</h3>

        </div>
        <div className="items">
          <div className="item">
            <img
              src={BellNotification}
              alt=""
              className="notification_bell_img"
              // class="text-4xl"
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickNotification}
            />
            <div className="counter">
              {adminProfile?.unreadNotification?.length !== 0
                ? adminProfile?.unreadNotification?.length
                : 0}
            </div>

            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={notification}
              open={openNotification}
              onClose={handleCloseNotification}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              class="absolute top-7"
            >
              {adminNotification?.map((item) => {
                return (
                  <div className="mainDiv">
                    <MenuItem
                      className="modalNotify"
                      onClick={handleCloseNotification}
                    >
                      <div className="mainNoti">
                        <div className="title">
                          <h3>{item?.title}</h3>
                        </div>
                        <div title={item?.body} className="body">
                          <span>{item?.body?.substring(0, 40) + "..."}</span>
                        </div>
                      </div>
                    </MenuItem>
                  </div>
                );
              })}
            </Menu>
          </div>

          <div className="item">
            <AccountMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const AccountMenu = () => {
  const{setCallStarted}=useScreenShareStore()
  const navigate = useNavigate();
  const logoutbtn = () => {
    localStorage.clear();
    setCallStarted(false)
    navigate("/");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* <IconButton size="small" sx={{ ml: 2 }}>
        <div
          style={{
            width: "auto",
            height: "auto",
            backgroundColor: "white",
            borderRadius: "15px",
            minHeight:'40px',
            maxHeight:'50px',
            minWidth:'150px'
          }}
        >
          <div className="flex gap-2 items-center">
            <img src={adminimg} alt="" className="admin_img" style={{marginLeft:'0px'}} />
            <div>
              <h3
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "13px",
                }}
              >
                Vivek Singh
              </h3>
              <h3
                style={{
                  fontSize: "12px",
                }}
              >
                React js
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: "1.0rem",
                marginRight:'10px'
              }}
              onClick={handleClick}
            >
              <IoIosArrowDown />
            </div>
          </div>
        </div>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem component={Link} to="/profile">
          <FaUserCircle
            style={{
              fontSize: "20px",
              marginRight: "6px",
            }}
          />
          Profile
        </MenuItem>

        <Divider />

        <MenuItem >
        
          <LogoutAlert/>
        </MenuItem>
      </Menu> */}
    </>
  );
};
