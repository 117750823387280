import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import authService from "./../service/authServices";

const useAuthHook = () => {
  const navigate = useNavigate();

  const loginAdminMutation = useMutation(
    (data) => authService.loginAdmin(data.adminEmail, data.adminPassword),
    {
      onSuccess: (response) => {
        if (response) {
          localStorage.setItem("token", response.token);
          navigate("/dashboard");
        }
      },
    }
  );

  return {
    loginAdminMutation,
  };
};

export default useAuthHook;
