import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosInstance = axios.create({
  // baseURL: 'https://jsonplaceholder.typicode.com',
  // other configurations
})
const handleUnauthorizedError = () => {
  localStorage.clear();
  // toast.error("Session expired. Please login again.");
  setTimeout(() => {
    window.location.href = "/";
  }, 2000);
};

const handleNetworkError = (error) => {
  toast.error("Network error. Please check your internet connection.");
};

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const successMessage = response.data.message;
    if (
      successMessage &&
      ["post",'put', "delete"].includes(response.config.method.toLowerCase())
    ) {
      toast.success(successMessage);
    }

    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        handleUnauthorizedError();
        return Promise.reject(new Error("Unauthorized access"));
      } else if (error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        console.error("API error:", errorMessage);
        if (error.config && error.config.method && error.config.method.toLowerCase() !== 'get') {
          toast.error(errorMessage);
        }
      } else {
        console.error("HTTP error:", error.response.status);
      }
    } else if (error.request) {
      handleNetworkError(error);
    } else {
      console.error("Request error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default axios;
