import { id } from "date-fns/locale";
import { baseUrl } from "../../config/baseUrl";
import axios from "./../../config/axiosAuth";
import { date } from "zod";
import MonthlyAttendance from "./MonthlyAttendence";

const attendanceServices = {
  getAttendance: async (body) => {
    const { data } = await axios.get(baseUrl + "/dailyAttendance", {});
    return data.usersAttendance;
  },
  getMonthlyAttendance: async (body) => {
    const response = await axios.get(`${baseUrl}/monthlyAttendance?employeeId=${body.employeeId}&&month=${body.month}&&year=${body.year}&&startDate=${body.startDate}&&endDate=${body.endDate}`, 
     );
    return response?.data;
  },
};


export default attendanceServices;
