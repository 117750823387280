import React from "react";
import Modal from "react-modal";

export const CommentModal = ({ isOpen, onClose, data }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Employee Comment Modal"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      {data && (
        <div>
          <div
            style={{
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <h5
                style={{
                  fontWeight: "bold",
                }}
              >
                Employees
                <span className="close" onClick={onClose}>
                  &times;
                </span>
              </h5>
            </div>
            <table>
              <thead>
                <tr
                  style={{
                    backgroundColor: "#f1f4f7",
                    color: "gray",
                  }}
                >
                  <th>Name</th>
                  <th>Comment</th>
                  <th>Employee ID</th>
                  <th>Designation</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {data?.commentByEmployee?.map((receiver) => (
                  <tr key={receiver?._id}>
                    <td>{receiver?.commentBy?.name}</td>
                    <td>{receiver?.comment}</td>
                    <td>{receiver?.commentBy?.employeeId}</td>
                    <td>{receiver?.commentBy?.designation?.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Modal>
  );
};
