import Mic from '../../../Assets/CallActionImages/Mic.png';
import Mobile from '../../../Assets/CallActionImages/Mobile.png';
import muteMic from '../../../Assets/CallActionImages/MuteMic.png';
import MuteVideo from '../../../Assets/CallActionImages/MuteVideo.png';
import Video from '../../../Assets/CallActionImages/Video.png';
import ScreenToggle from '../../../Assets/CallActionImages/ScreenToggle.png';
import speaker from '../../../Assets/CallActionImages/SpeakerOnLight.png';

export const enabledMic =Mic;
export const endcallButton=Mobile;
export const disableMic=muteMic
export const enabledVideo =Video;
export const enterFullScreen=ScreenToggle;
export const disableVideo=MuteVideo;
export const enableSpeaker=speaker;
