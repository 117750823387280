// AddFollowUpModal.js
import React from "react";
import TextField from "@mui/material/TextField";

import { useForm, Controller } from "react-hook-form";
import useFollowUpHooks from "./followUpHooks";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";

import { AddIcon } from "../../components/ui/icons.jsx";
import { Button } from "../../components/ui/button.jsx";

const AddFollowUpModal = ({ isOpen, onClose }) => {
  const { control, handleSubmit, setValue, formState,register } = useForm();

  const { errors } = formState;
  const { addFollowUpMutation } = useFollowUpHooks();

  const statusOption = ["Approved", "Rejected", "Pending"];

  const onSubmit = (data) => {
    addFollowUpMutation.mutate(data);
    onClose();
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const slicedValue = numericValue.slice(0, 10);
    setValue("phone", slicedValue);
  };

  return (
    <Dialog>
      <DialogTrigger className="flex text-sm items-center gap-2">
        <span> Add FollowUps</span>
        <AddIcon />
      </DialogTrigger>
      <DialogContent>
        <div>
          <h2 id="modal-title" className="mb-4">
            Add Follow Up
          </h2>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="candidateName"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "Candidate Name is required",
                  },
                  maxLength: {
                    value: 40,
                    message: "Maximum 40 characters are allowed",
                  },
                  minLength: {
                    value: 3,
                    message: "Minimum 3 characters are allowed",
                  },
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "Only letters and spaces are allowed",
                  },

                  validate: (value) =>
                    value.trim().length > 0 || "Only spaces are not allowed",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Candidate Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.candidateName}
                    helperText={errors.candidateName?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                    message: "Invalid email address",
                  },

                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed",
                  },

                  minLength: {
                    value: 3,
                    message: "Minimum 3 characters are allowed",
                  },

                  validate: (value) =>
                    value.trim().length > 0 || "Only spaces are not allowed",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Phone is required",
                  },
                  pattern: {
                    value: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
                    message: "Only numbers are allowed",
                  },
                  maxLength: {
                    value: 10,
                    message: "Phone must be 10 digits",
                  },
                  minLength: {
                    value: 10,
                    message: "Phone must be 10 digits",
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    onChange={handlePhoneChange}
                 
                  />
                )}
              />
              <Controller
                name="applyFor"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Apply For is required",
                  },
                  maxLength: {
                    value: 30,
                    message: "Apply For must not exceed 30 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Apply For must be at least 3 characters",
                  },
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "Only letters and spaces are allowed",
                  },
                  validate: (value) =>
                    value.trim().length > 0 || "Only spaces are not allowed",
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Apply For"
                    variant="outlined"
                    fullWidth
                    error={!!errors.applyFor}
                    helperText={errors.applyFor?.message}
                  />
                )}
              />
            </div>

            <Controller
              name="status"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Status is required",
                },

                maxLength: {
                  value: 15,
                  message: "Status must not exceed 15 characters",
                },

                minLength: {
                  value: 1,
                  message: "Can not be empty",
                },
                validate: (value) =>
                  value.trim().length > 0 || "Only spaces are not allowed",
              }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="status"
                  variant="outlined"
                  fullWidth
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option aria-label="None" value="" />

                  {statusOption?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              )}
            />

            <DialogFooter>
              {formState.isValid && (
                <DialogClose asChild>
                  <Button type="submit">Save</Button>
                </DialogClose>
              )}

              {!formState.isValid && <Button type="submit">Save</Button>}
            </DialogFooter>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddFollowUpModal;
