import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useNotificationHook from "../../hooks/notificationHooks";
import useEmployeeStore from "../../store/employeeStore";
import "./sendNotification.css";
import { Button } from "../../components/ui/button";
import { ResetIcon } from "@radix-ui/react-icons";
import useEmployeeHook from "../../hooks/useEmployeeHook";

const SendNotification = () => {
  // const { employees } = useEmployeeStore();
  const{data:employees}=useEmployeeHook()
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [allSend, setSendAll] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { sendToAllMutation, sendToFewMutation } = useNotificationHook();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  console.log(employees,'employees')

  const handleData = (e) => {
    setSendAll(e.target.value);
    setSelectedEmployee("");
  };

  const handleEmployeeSelection = (e) => {
    setSelectedEmployee(e.target.value);
  };

  const sendToAll = (e) => {
    e.preventDefault();
    const notificationData = {
      title: title,
      body: message,
    };

    if (!title.trim() || !message.trim()) {
      toast.error("Please fill all the fields.");
      return;
    }

    sendToAllMutation.mutate(notificationData);
    setMessage('')
    setTitle('')
    setSendAll('')
    setSelectedEmployee('')
    setSelectedEmployeeIds('')
  };

  const sendToFew = (e) => {
    e.preventDefault();
    if (selectedEmployeeIds?.length === 0) {
      toast.error("Please select at least one employee.");
      return;
    }

    if (!title.trim() || !message.trim()) {
      toast.error("Please fill all the fields.");
      return;
    }

    const notificationData = {
      title: title,
      body: message,
      to: selectedEmployeeIds,
    };

    sendToFewMutation.mutate(notificationData);
    setMessage('')
    setTitle('')
    setSendAll('')
    setSelectedEmployee('')
    setSelectedEmployeeIds('')
  };

  const handleAddEmployee = (e) => {
    e.preventDefault();
    if (selectedEmployee) {
      if (!selectedEmployeeIds.includes(selectedEmployee)) {
        setSelectedEmployeeIds([...selectedEmployeeIds, selectedEmployee]);
        setSelectedEmployee("");
      } else {
        // toast.error("Employee already selected");
      }
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    setSelectedEmployeeIds([]);
    setSelectedEmployee("");
    toast.success("Reset Successfully");
  };

  return (
    <>
      <h3>Send Notification</h3>

      <form className="sendNotificationForm">
        <div
          className="sendNotificationInputBox"
          style={{ marginBottom: "20px" }}
        >
          <span>To</span>
          <select
            className="sendNotificationSelectOption"
            onChange={handleData}
            value={allSend}
          >
            <option value="">Select to send notification</option>
            <option value="All">All</option>
            <option value="Employee">Employee</option>
          </select>
        </div>
        {allSend === "Employee" && (
          <div
            className="sendNotificationInputBox"
            style={{
              marginBottom: "20px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "80%",
              }}
            >
              <span>Choose Employee(s)</span>
              <select
                className="sendNotificationSelectOption"
                onChange={handleEmployeeSelection}
                value={selectedEmployee}
              >
                <option value="">Select Employee</option>
                {employees.map((employee) => (
                  <option value={employee._id} key={employee._id}>
                    {employee.name} ({employee.employeeId})
                  </option>
                ))}
              </select>
            </div>
            <button
              className="btn btn-primary"
              style={{
                alignSelf: "flex-end",
              }}
              onClick={handleAddEmployee}
            >
              Add
            </button>
            <button
              style={{
                alignSelf: "flex-end",
                border: "none",
                backgroundColor: "red",
              }}
              className="btn btn-primary"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            marginBottom: "20px",
          }}
        >
          {selectedEmployeeIds?.length > 0 && allSend === "Employee" && (
            <div
              className="todo-list" // Add a custom class name for the component
              style={{
                width: "300px",
                backgroundColor: "white",
                borderRadius: "7px",
                boxSizing: "border-box",
                padding: "10px",
                overflow: "hidden",
              }}
            >
              <div className="header">
                <div className="header-text">Selected Employee(s)</div>
                <div className="divider"></div>
              </div>
              <div
                style={{
                  overflowY: "scroll",
                  maxHeight: `${3 * 20}px`,
                }}
              >
                {selectedEmployeeIds?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "5px 0",
                      fontSize: "11px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <div className="compleated_button"></div>
                      <span>
                        {
                          employees.find((employee) => employee._id === item)
                            ?.name
                        }
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              {selectedEmployeeIds?.length > 2 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                    // Add an onClick event to handle scrolling
                  }}
                >
                  <KeyboardArrowDownIcon fontSize="small" />
                </div>
              )}
            </div>
          )}
        </div>

        <div
          className="sendNotificationInputBox"
          style={{ marginBottom: "20px" }}
        >
          <span>Title</span>
          <input
            type="text"
            name="title"
            onChange={(e) => setTitle(e.target.value)}
            autoComplete="off"
            value={title}
            minLength={5}
          />
        </div>

        <div
          className="sendNotificationInputBox"
          style={{ marginBottom: "20px" }}
        >
          <span>Message</span>
          <textarea
            name="message"
            rows="2"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            minLength={5}
          />
        </div>

        {allSend === "All" ? (
          <Button className="btn btn-primary" onClick={sendToAll} type="button">
            Send
          </Button>
        ) : (
          <Button className="btn btn-primary" onClick={sendToFew} type="button">
            Send
          </Button>
        )}
      </form>
    </>
  );
};

export default SendNotification;
