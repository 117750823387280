import React, { useState } from "react";
import "./login.css";
import "react-toastify/dist/ReactToastify.css";
import { AdminLogin } from "./AdminLogin";
import { useEffect } from "react";

const Login = () => {
  return (
    <div className="login-container">
      <div className="login-bg">
        <div className="login-top">
          <div>Admin</div>
        </div>
        <AdminLogin />
      </div>
    </div>
  );
};

export default Login;
