import { createContext, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import io from "socket.io-client";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { base } from "../config/baseUrl";

// export const socketContext = createContext();

const SocketProvider = ({ children }) => {
  const TOKEN = localStorage.getItem("token");
  const socketRef = useRef(null);
  const zegoRef = useRef(null);
  //   zegoRef.current.setDebugVerbose(false);
  useEffect(() => {
    socketRef.current = io.connect(base, {
      extraHeaders: {
        token: TOKEN,
        sessionId: localStorage.getItem("videoSessionId"),
      },
    });
    /////

    let appId = 2078651070;
    let serverSecret = "c93a8ab5be0f6e7bf626f1bfb87e77b2";
    zegoRef.current = new ZegoExpressEngine(appId, serverSecret);
    // zegoRef.current.enableMultiRoom(true);
    zegoRef.current.setDebugVerbose(false);
  }, []);
  //   if (!socketRef.current) {
  //     return null; // or loading indicator if needed
  //   }

  return <Outlet context={[socketRef, zegoRef]}>{children}</Outlet>;

  //   return (
  //     // <socketContext.Provider value={{ socketRef, zegoRef }}>
  //     //   {children}
  //     // </socketContext.Provider>
  //     <>
  //       <Outlet context={[socketRef, zegoRef]} />
  //     </>
  //   );
};

export default SocketProvider;
