/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate } from "react-router-dom";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { DailyLogs } from "../../components/modals/DailyLogsModal";
import { AddCommentModal } from "../reports/AddCommentModal";
// import { DailyLogs } from "../../components/modals/DailyLogsModal";

function formatDate(dateString) {
  const dateObject = new Date(dateString);
  return dateObject.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}
export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    header: "Name",
    cell: ({ row }) => {
      return <span>{row.original.name}</span>;
    },
  },

  {
    header: "Employee ID",
    cell: ({ row }) => {
      return <span>{row.original.employeeId}</span>;
    },
  },
  {
    header: "Role",
    cell: ({ row }) => {
      return <span>{row.original?.role}</span>;
    },
  },
  {
    header: "Designation",
    cell: ({ row }) => {
      return <span>{row.original?.designation?.name}</span>;
    },
  },

  {
    header: "Contact Number",
    cell: ({ row }) => {
      return <span>{row.original?.number}</span>;
    },
  },
  {
    header: "email",
    cell: ({ row }) => {
      return <span>{row.original?.email}</span>;
    },
  },
  {
    header: "Monthly Reports",
    cell: ({ row }) => {
      const navigate = useNavigate();

      const handleViewClick = () => {
        navigate(`/monthlyReports/${row.original._id}`);
      };

      return (
        <button
          onClick={handleViewClick}
          style={{
            padding: "5px 10px",
            backgroundColor: "#085394",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          View
        </button>
      );
    },
  },

  // {
  //   header: "Daily Report",
  //   cell: ({ row }) => {
  //     return <DailyLogs id={row.original._id} />;
  //   },
  // },

  {
    header: "Add Comment",
    cell: ({ row }) => <AddCommentModal employeeId={row.original?._id} url={'/add-comment-in-report'} />,
  },
  {
    cell: ({ row }) => {
      return <CommentDialog employeeId={row.original?._id}  url={"/employee-comment-in-reports"} report={true} />;
    },
    header: "Comments",
  },
];

export const MonthlyColumn = [

    {
      header: "S.No",
      cell: ({ row }) => {
        return <span>{row.index + 1}</span>;
      },
    },

    {
      header: "Leaves",
      cell: ({ row }) => {
        return <span>{row.original?.designation?.name}</span>;
      },
    },
  
    {
      header: "Present Days",
      cell: ({ row }) => {
        return <span>{row.original?.number}</span>;
      },
    },
    {
      header: "Absent Days",
      cell: ({ row }) => {
        return <span>{row.original?.email}</span>;
      },
    },
    {
      header: "Leaves",
      cell: ({ row }) => {
        return <span>{row.original?.il}</span>;
      },
    },
   
    {
      header: "Office Days",
      cell: ({ row }) => {
        return <span>{row.original?.il}</span>;
      },
    },
    {
      header: "Hybrid Days",
      cell: ({ row }) => {
        return <span>{row.original?.il}</span>;
      },
    },
  
];
