import React, { useState } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";


import { useNavigate } from "react-router-dom";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "./alert-dialog";
import { cn } from "../../../utils/lib";

const CommonAlert = ({onClick,className, refprop,text,trigger,heading,button}) => {
  const[open ,setOpen]=useState(false)
// const{ref}=useComponentVisible()


    const navigate=useNavigate()
    // const logout=()=>{
    //     localStorage.clear();
    //     navigate('/login')
    //   }
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild className="w-[75px]">
          <p className={cn(" text-center cursor-pointer",className)} onClick={()=>setOpen(true)}> {trigger}</p>
      </AlertDialogTrigger>

      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle>{heading} </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
         {text}
        </AlertDialogDescription>
        <AlertDialogFooter ref={refprop}>
          <AlertDialogCancel  ref={refprop}className={cn(buttonstyles,'bg-errorPrimary')}>Cancel</AlertDialogCancel>
          <AlertDialogAction ref={refprop} onClick={onClick} className={cn(buttonstyles,'bg-successPrimary')}>{button?button:heading}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CommonAlert;
const buttonstyles = "w-[110px] !h-[35px] !hover:text-white rounded-[8px] !min-h-[35px] border-none text-white text-[14px] font-medium font-md uppercase !bg-[#2c3e50]";
