import { toast } from "react-toastify";
import { baseUrl } from "../../config/baseUrl";
import axios from "./../../config/axiosAuth";

const leadsServices = {
  getLeads: async () => {
    const result = await axios.post(baseUrl + "/getAllLeads", {});
    return result.data.results;
  },

  addLead: async (data) => {
    const result = await axios.post(baseUrl + "/addLeads", data);
    return result.data;
  },

  editLead: async (id, data) => {
    const result = await axios.post(baseUrl + `/editLeads/${id}`, data);
    return result.data;
  },

  deleteLead: async (id) => {
    const result = await axios.post(baseUrl + `/deleteLeads/${id}`);
    toast.success(result.data.message);
    return result.data;
  },
};

export default leadsServices;
