import "react-toastify/dist/ReactToastify.css";
import { AppContainer } from "./AppContainer.js";
import Layout from "./admin/layout.js";
import ProtectRoute from "./admin/pages/protectedPages/ProtectRoute";

function App() {
  return (
    <div className="app">
      <AppContainer></AppContainer>
    </div>
  );
}

export const renderProtectedRoute = (Component) => {
  return (
    <ProtectRoute
      Component={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};

export default App;
