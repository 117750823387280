// import React from 'react'
// import PeopleSection from './PeopleSection'

// const RightSection = ({state,kickUserFromRoom,muteUser}) => {
//     console.log(state,'roomId')
//   return (
//     <>
//     </>
//   )
// }

// export default RightSection
import React, { useState } from "react";
import PeopleSection from "./PeopleSection";
import ChatSection from "./ChatSection";
import { IoIosChatboxes } from "react-icons/io";
import { BsFillPeopleFill } from "react-icons/bs";

const RightSection = ({state, roomId,userName, socketRef,kickUserFromRoom,muteUser,messages,setMessages,userId }) => {
  const[showchat,setShowchat]=useState(true);
  const[showPeople,setShowPeople]=useState(false)
  const openChat=()=>{
    setShowchat(true)
    setShowPeople(false)
  }

  const openPeople=()=>{
    setShowchat(false)
    setShowPeople(true)
  }
  // console.log(roomId, "roomId");
  return (
    <>
    <div className="right_section_options">

    <div onClick={openChat}>  <IoIosChatboxes color="white" size="28px" /></div>  <div onClick={openPeople}><BsFillPeopleFill  color="white" size="28px"/></div>
    </div>
      {showchat && <ChatSection userName={userName} socketRef={socketRef} userId={userId} roomId={state} messages={messages} setMessages={setMessages}/>}
      {showPeople &&   <PeopleSection roomId={state} kickUserFromRoom={kickUserFromRoom} muteUser={muteUser}/>
      }
    </>
  );
};

export default RightSection;
