import axios from "../config/axiosAuth";
import { baseUrlCommon, baseUrl } from "../config/baseUrl";
const authService = {
  getRole: async () => {
    try {
      const response = await axios.get(`${baseUrlCommon}/roleCheck`);
      return response.data.result;
    } catch (error) {
      console.error("Error getting role", error);
    }
  },
  zegoToken: async () => {
    const response = await axios.get(`${baseUrl}/adminZegoToken`);
    return response.data;
  },
  loginAdmin: async (email, password) => {
    const response = await axios.post(`${baseUrl}/login`, {
      email: email.trim(),
      password: password.trim(),
    });
    return response.data;
  },

  sendOtpAdmin: async (email) => {
    const response = await axios.post(`${baseUrl}/adminForget`, {
      email: email,
    });
    return response.data;
  },

  resetPasswordAdmin: async (email, otp, newPassword) => {
    try {
      const response = await axios.post(`${baseUrl}/resetPassword`, {
        email: email,
        otp: otp,
        newPassword: newPassword,
      });
      return response.data;
    } catch (error) {
      console.error("Error resetting password", error);
      throw error;
    }
  },
};

export default authService;
