import React from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export function EmployeeList(props) {
  return (
    <Modal
      open={props.isEmployeeListOpen}
      onClose={() => props.setIsEmployeeListOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isEmployeeListOpen}>
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            padding: "16px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <TextField
              fullWidth
              label="Search Employee"
              variant="outlined"
              value={props.searchText}
              onChange={props.handleSearch}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
          </div>
          <List
            style={{
              maxHeight: "400px",
              overflow: "auto",
            }}
          >
            {props.filteredEmployees.map((employee) => (
              <ListItem
              className="cursor-pointer"
                key={employee.id}
                onClick={() => {
                  props.handleEmployeeSelect(employee._id);
                }}
              >
                <ListItemAvatar>
                  <Avatar>{employee.name[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={employee.name}
                  secondary={employee.position}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Fade>
    </Modal>
  );
}
