import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useClientStore from "../store/clientStore";
import { clientServices } from "../service/clientServices";

const useClientHook = () => {
  const fetchClients = async () => {
    const res = await clientServices.getAllClient();
    return res;
  };

  const { getClient, totalClientCount, setTotalClientCount } = useClientStore();
  const queryClient = useQueryClient();

  const { data, isLoading, isError, error, refetch } = useQuery(
    "client",
    fetchClients
  );

  useEffect(() => {
    if (!isLoading && !isError) {
      getClient(data);
      setTotalClientCount(data?.length);
    }
  }, [isLoading, isError]);

  const editClientMutation = useMutation(
    (data) => {
      return clientServices.updateClient(data.id, data.data);
    },
    {
      onSuccess: () => {

        queryClient.invalidateQueries("client");
      },
    }
  );

  const addClientMutation = useMutation(
    (data) => {
      return clientServices.addClient(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("client");
        return data
      },
    }
  );

  const deleteClientMutation = useMutation(
    (id) => {
      return clientServices.deleteClient(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("client");
      },
    }
  );

  return {
    getClient,
    totalClientCount,
    setTotalClientCount,
    data,
    isLoading,
    isError,
    error,
    editClientMutation,
    addClientMutation,
    deleteClientMutation,
    refetch,
  };
};

export default useClientHook;
