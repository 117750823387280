import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useEventStore from "../../../store/eventStore";
import useEventHook from "../../../hooks/eventsHooks";

export const EditEvent = ({ isOpen, closeModal, id }) => {
  const { deleteOtherEvents, findOtherEvent } = useEventStore();

  const eventToEdit = findOtherEvent(id);

  const { updateOtherEventMutation, deleteOtherEventsMutation } =
    useEventHook();

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");

  React.useEffect(() => {
    if (eventToEdit) {
      setSubject(eventToEdit.subject);
      setDescription(eventToEdit.description);
      setDate(eventToEdit.date);
    }
  }, [eventToEdit]);

  const handleSubmit = async () => {
    if (!date || !subject || !description) {
      toast.error("Please fill in all fields");
      return;
    }

    updateOtherEventMutation.mutate({
      data: {
        date: date,
        subject: subject,
        description: description,
      },
      id: id,
    });
    closeModal();
  };

  const handleDelete = () => {
    deleteOtherEventsMutation.mutate(id);
    deleteOtherEvents(id);
    closeModal();
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>Edit Event</DialogTitle>
      <DialogContent>
        <form>
          <div>
            <TextField
              label="Subject"
              type="text"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              fullWidth
              style={{ marginTop: "8px" }}
            />
          </div>

          <div>
            <TextField
              label="Description"
              type="text"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              fullWidth
              style={{ marginTop: "8px" }}
            />
          </div>

          <div>
            <TextField
              label="Date"
              type="date"
              onChange={(e) => setDate(e.target.value)}
              value={date}
              fullWidth
              InputLabelProps={{
                shrink: true, // Keep the label above the input
              }}
              InputProps={{
                inputProps: {
                  min: "2022-01-01", // Adjust the minimum date as needed
                },
                placeholder: "dd/mm/yyyy", // Custom placeholder text
              }}
              style={{ marginTop: "8px" }}
            />
          </div>
          <br />
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Edit Event
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="secondary"
            style={{ marginLeft: "10px" }}
          >
            Delete
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
