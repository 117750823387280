// EditFollowUpModal.js
import React from "react";

import { useForm, Controller } from "react-hook-form";
import useFollowUpHooks from "./followUpHooks";
import { useEffect } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";

import { Button } from "../../components/ui/button.jsx";

import { EditIcon } from "../../components/ui/icons.jsx";
import { TextField } from "@mui/material";

const EditFollowUpModal = ({ followUp }) => {
  const { control, handleSubmit, setValue, formState } = useForm();

  console.log("Follow.", followUp);

  const { errors } = formState;

  const statusOptions = ["Approved", "Rejected", "Pending"];
  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;

    const numericValue = inputValue.replace(/[^0-9]/g, "");

    const slicedValue = numericValue.slice(0, 10);
    setValue("phone", slicedValue);
  };

  const { deleteFollowUpMutation, editFollowUpMutation } = useFollowUpHooks();

  const onSubmit = (data) => {
    editFollowUpMutation.mutate({ id: followUp?._id, data: data });
  };

  useEffect(() => {
    if (followUp) {
      const { candidateName, email, phone, applyFor, status } = followUp;

      setValue("candidateName", candidateName || "");
      setValue("email", email || "");
      setValue("phone", phone || "");
      setValue("applyFor", applyFor || "");
      setValue("status", status || "");
    }
  }, [followUp, setValue]);
  // const handlePhoneChange = (e) => {
  //   const inputValue = e.target.value;
  //   const numericValue = inputValue.replace(/[^0-9]/g, "");
  //   const slicedValue = numericValue.slice(0, 10);
  //   setValue("phone", slicedValue);
  // };
  return (
    <Dialog>
      <DialogTrigger>
        <EditIcon className="text-primary " />
      </DialogTrigger>
      <DialogContent>
        <div>
          <h2 id="modal-title" className="mb-4">
            Edit Follow Up
          </h2>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="candidateName"
                control={control}
                defaultValue={followUp?.candidateName}
                rules={{
                  maxLength: {
                    value: 30,
                    message: "Candidate Name must not exceed 30 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Candidate Name must be at least 3 characters",
                  },
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "Only letters and spaces are allowed",
                  },

                  validate: (value) =>
                    value?.trim().length > 0 || "Only spaces are not allowed",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Candidate Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.candidateName}
                    helperText={errors.candidateName?.message}
                  />
                )}
              />
              <Controller
                name="email"
                rules={{
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                    message: "Invalid email address",
                  },

                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed",
                  },

                  minLength: {
                    value: 3,
                    message: "Minimum 3 characters are allowed",
                  },
                  validate: (value) =>
                    value?.trim().length > 0 || "Only spaces are not allowed",
                }}
                control={control}
                defaultValue={followUp?.email}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="phone"
               
                type='number'
                control={control}
                rules={{
                  maxLength: {
                    value: 10,
                    message: "Phone must be 10 digits",
                  },
                  pattern: {
                    value: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
                    message: "Only numbers are allowed",
                  },
                  minLength: {
                    value: 10,
                    message: "Phone must be 10 digits",
                  },
                  validate: (value) =>
                    value?.trim().length > 0 || "Only spaces are not allowed",
                }}
                defaultValue={followUp?.phone}
                render={({ field }) => (
                  <TextField
                    {...field}
                     maxLength='10'
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    onChange={handlePhoneChange}
                  />
                )}
              />
              <Controller
                name="applyFor"
                rules={{
                  maxLength: {
                    value: 30,
                    message: "Apply For must not exceed 30 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Apply For must be at least 3 characters",
                  },
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "Only letters and spaces are allowed",
                  },
                  validate: (value) =>
                    value?.trim().length > 0 || "Only spaces are not allowed",
                }}
                control={control}
                defaultValue={followUp?.applyFor}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Apply For"
                    variant="outlined"
                    error={!!errors.applyFor}
                    helperText={errors.applyFor?.message}
                    fullWidth
                  />
                )}
              />
            </div>

            <Controller
              name="status"
              control={control}
              rules={{
                minLength: {
                  value: 1,
                  message: "Can not be empty",
                },

                maxLength: {
                  value: 15,
                  message: "Maximum 15 characters are allowed",
                },
                validate: (value) =>
                  value?.trim().length > 0 || "Only spaces are not allowed",
              }}
              defaultValue={followUp?.status}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Status"
                  variant="outlined"
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option aria-label="None" value="" />
                  {statusOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              )}
            />

            <DialogFooter>
              {formState.isValid && (
                <DialogClose asChild>
                  <Button type="submit">Save</Button>
                </DialogClose>
              )}

              {!formState.isValid && <Button type="submit">Save</Button>}

              <DialogClose asChild>
                <Button
                  onClick={() => deleteFollowUpMutation.mutate(followUp?._id)}
                  // isLoading={deleteLeadMutation.isLoading}
                >
                  Remove
                </Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditFollowUpModal;
