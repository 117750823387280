import React, { useState, useEffect } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const App = () => {
  const [chartData, setChartData] = useState([
    // { label: "Trading", y: 10 },
    // { label: "Office", y: 15 },
    // { label: "Backend", y: 25 },
    // { label: "Frontend", y: 30 },
    // { label: "Lloyd Project", y: 28 },
  ]);

  const handleAllprojecttaskperncentage = () => {
    axios
      .get(baseUrl + "/taskPercentageOfProjects")
      .then((res) => {
        console.log(res.data);
        const projectProgress = res.data?.projectProgress.map(
          (item, value) => ({ y: parseFloat(item.finalPercentage), label: item.projectName })
        );
        console.log(projectProgress,'projectProgress')
        // Assuming the API returns data in the format we need
        // if (res.data && Array.isArray(res.data.projectProgressa)) {
          setChartData(projectProgress);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleAllprojecttaskperncentage();
  }, []);

  const options = {
    height: 270,
    title: {
      text: "All Projects Task Percentage",
      fontSize: 12,
      fontWeight: "normal",
      textAlign: "left",
      horizontalAlign: "left",
    },
    dataPointMaxWidth: 25,
    dataPointMinWidth: 20,
    axisY: {
      labelFontSize: 0,
      gridThickness: 0,
      titleFontColor: "grey",
      titleFontSize: 16,
      titleFontWeight: "Bold",
      lineColor: "",
      tickThickness: 0,
      lineThickness: 0,
      minimum: 0,  // Set minimum to 0
      maximum: 100, 
    },
    legend: {
      verticalAlign: "top",
      horizontalAlign: "right",
      reversed: true,
      cursor: "pointer",
      // itemclick: this.toggleDataSeries
    },
    axisX: {
      labelAngle: 0,
      labelFontSize: 0,
      labelTextAlign: "center",
      gridThickness: 0,
      tickThickness: 0,
      tickLength: 0,
      titleFontColor: "grey",
      titleFontSize: 16,
      titleFontWeight: "Bold",
      lineColor: "",
      lineThickness: 0,
    },
    data: [
      {
        showInLegend: true,
        type: "column",
        dataPoints: chartData,
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default App;
