import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { employeeServices } from "../service/employeeService";
import useEmployeeStore from "../store/employeeStore";
import { useDebounce } from 'use-debounce';

const useEmployeeHook = (params={}) => {
  const [debouncedParams] = useDebounce(params, 300);
  const fetchEmployees = async (params) => {
    const res = await employeeServices.getAllEmployee(debouncedParams);
    return res.employees;
  };
  const fetchCredentialRequests = async () => {
    const res = await employeeServices.getCRedentialRequests();
    return res;
  };
const fetchEmployeeProjectsMutation=useMutation(
  (data) => {
    return employeeServices.getemployeeProjects(data);
  },
  {
    onSuccess: (data) => {
      // updateEmployee(data);
      queryClient.invalidateQueries("employee");
    },
  }
);
const sendCredentialsMutation=useMutation(
  (data) => {
    return employeeServices.sendCredentials(data);
  },
  {
    onSuccess: (data) => {
      // updateEmployee(data);
      queryClient.invalidateQueries("employee");
    },
  }
);
const deleteEmployeeMUtation=useMutation(
  (data) => {
    return employeeServices.deleteEmployee(data);
  },
  {
    onSuccess: (data) => {
      // updateEmployee(data);
      queryClient.invalidateQueries("employee");
    },
  }
);
  const fetchResponsibilities = async () => {
    const res = await employeeServices.getResponsibilities();

    return res;
  };

  const fetchSalary = async () => {
    const res = await employeeServices.getPayRolls();
    return res;
  };

  const fetchEmpMonth = async () => {
    const res = await employeeServices.getEmpMonth();
    return res;
  };
  const fetchTestersList = async () => {
    const res = await employeeServices.getTestersList();
    return res;
  };
  const fetchDepartmentList = async () => {
    const res = await employeeServices.getDepartmentList();
    return res.data.result;
  };
  const fetchDesignationList = async () => {
    const res = await employeeServices.getDesignationList();
    return res.data.data;
  };
  const fetchEmployeeById=async(id)=>{
    const res =await employeeServices.getEmployeeById(id);
    return res
  }
  const fetchDeletedUsers=async(id)=>{
    const res =await employeeServices.fetchDeletedUsers(id);
    return res
  }

  const { addEmployee, setTotalEmp, totalEmp, updateEmployee } =
    useEmployeeStore();
  const queryClient = useQueryClient();
  const { data, isLoading, isError, error } = useQuery(
   [ "employee",debouncedParams.designation||'',debouncedParams.name||''],
   ()=> fetchEmployees(debouncedParams)
  );

  const { data: responsibilty } = useQuery(
    "responsibility",
    fetchResponsibilities
  );
  const { data: credRequests } = useQuery(
    "fetchCredentialRequests",
    fetchCredentialRequests
  );

  const { data: testersList } = useQuery("testersList", fetchTestersList);

  const { data: departmentList } = useQuery(
    "departmentList",
    fetchDepartmentList
  );
  const { data: deletedUsers } = useQuery(
    "deletedUsers",
    fetchDeletedUsers
  );
const {data:designationList}=useQuery(
  "designationList",fetchDesignationList
)
  const {
    data: salary,
    isError: salError,
    isLoading: salLoading,
  } = useQuery("salary", fetchSalary);

  const { data: empMonth } = useQuery("empMonth", fetchEmpMonth);
const{data:employeeById}=useQuery(['empById',debouncedParams.id],() => fetchEmployeeById(debouncedParams.id), {
  })

  useEffect(() => {
    if (!isLoading && !isError) {
      addEmployee(data);
      setTotalEmp(data.length);
    }
  }, [isLoading, isError]);

  
  const editEmployeeMutation = useMutation(
    (data) => {
      return employeeServices.updateEmployee(data.data, data.id);
    },
    {
      onSuccess: (data) => {
        updateEmployee(data);
        queryClient.invalidateQueries("employee");
        // toast.success("Employee updated successfully");
      },
    }
  );
  const revokeEmployeeProject = useMutation(
    (data) => {
      return employeeServices.revokeEmployeeProject(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("employee");
        // toast.success("Employee updated successfully");
      },
    }
  );

  const addEmployeeMutation = useMutation(
    (data) => {
      return employeeServices.addEmployee(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("employee");
        queryClient.invalidateQueries('departmentList')
      },
    }
  );

  const deleteEmployeeMutation = useMutation(
    (id) => {
      return employeeServices.deleteEmployee(id);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("employee");
      },
    }
  );

  const addResponsibilitiesMutation = useMutation(
    (data) => {
      return employeeServices.addResponsibilities(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("employee");
        queryClient.invalidateQueries("responsibility");
      },
    }
  );

  const takeResponsibilityBackMutation = useMutation(
    (data) => {
      return employeeServices.takeResponsibilityBack(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("responsibility");
      },
    }
  );
 

  const addEmployeeOfMonthMutation = useMutation(
    (data) => {
      return employeeServices.addEmpMonth(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("empMonth");
      },
    }
  );

  const suspendEmployeeTillDateMutation = useMutation((data) => {
    return   employeeServices.suspendEmployeeTillDate(data),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("employee");
        },
      }
    
  });

  const employeeActiveStatusMutation = useMutation((data) => {
    return employeeServices.employeeActiveStatus(data),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("employee");
        },
      }
    
  });

  return {
    data,
    isLoading,
    isError,
    error,
    editEmployeeMutation,
    addEmployeeMutation,
    deleteEmployeeMutation,
    totalEmp,
    addResponsibilitiesMutation,
    responsibilty,
    salError,
    salLoading,
    takeResponsibilityBackMutation,
    addEmployeeOfMonthMutation,
    empMonth,
    suspendEmployeeTillDateMutation,
    employeeActiveStatusMutation,
    salary,
    testersList,
    departmentList,
    employeeById,
    fetchEmployeeProjectsMutation,
    revokeEmployeeProject,
    designationList,
    deleteEmployeeMUtation,
    sendCredentialsMutation,
    credRequests,
    deletedUsers

  };
};

export default useEmployeeHook;
