// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";

// import "./login.css";
// import authService from "../../service/authServices";
// import { Button } from "../../components/ui/button";
// import { Input } from "../../components/ui/input";

// const ForgetPass = () => {
//   const navigate = useNavigate();

//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   const [email, setEmail] = useState("");
//   const [otp, setOtp] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [showOtpAndNewPasswordInputs, setShowOtpAndNewPasswordInputs] =
//     useState(false);

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleOtpChange = (e) => {
//     setOtp(e.target.value);
//   };

//   const handleNewPasswordChange = (e) => {
//     setNewPassword(e.target.value);
//   };

//   const handleConfirmPasswordChange = (e) => {
//     setConfirmPassword(e.target.value);
//   };

//   const sendOtp = async (e) => {
//     e.preventDefault();
//     try {
//       await authService.sendOtpAdmin(email);
//       setShowOtpAndNewPasswordInputs(true);
//     } catch (error) {
//       // toast.error("Error sending OTP");
//     }
//   };

//   const resetPassword = async (e) => {
//     e.preventDefault();

//     if (newPassword !== confirmPassword) {
//       toast.error("Passwords do not match");
//       return;
//     }

//     try {
//       await authService.resetPasswordAdmin(email, otp, newPassword);
//       toast.success("Password reset successfully");
//       navigate("/");
//     } catch (error) {
//       toast.error("Error resetting password");
//     }
//   };

//   const toggleNewPasswordVisibility = () => {
//     setShowNewPassword((prev) => !prev);
//   };

//   const toggleConfirmPasswordVisibility = () => {
//     setShowConfirmPassword((prev) => !prev);
//   };

//   return (
//     <div
//       style={{
//         width: "100vw",
//         height: "100vh",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <div
//         style={{ width: "30rem", height: "auto" }}
//         className="border-2 p-4 rounded-lg"
//       >
//         <div className="grid gap-3">
//           <h2 className="text-2xl">Reset Password</h2>

//           <h3 className="forgettext">
//             Please enter your email address and we'll send your email an OTP to
//             reset your password.
//           </h3>
//           <form className="emailForm">
//             <div className="mb-3" controlId="formBasicEmail">
//               <label>Email address</label>
//               <Input
//                 type="email"
//                 placeholder="Enter email"
//                 value={email}
//                 onChange={handleEmailChange}
//               />
//             </div>
//             {showOtpAndNewPasswordInputs && (
//               <>
//                 <div className="mb-3" controlId="formBasicOTP">
//                   <label>OTP</label>
//                   <Input
//                     type="text"
//                     placeholder="Enter OTP"
//                     value={otp}
//                     onChange={handleOtpChange}
//                   />
//                 </div>
//                 <div className="mb-3" controlId="formBasicNewPassword">
//                   <label>New Password</label>
//                   <Input
//                     type={showNewPassword ? "text" : "password"}
//                     placeholder="Enter new password"
//                     value={newPassword}
//                     onChange={handleNewPasswordChange}
//                   />
//                 </div>
//                 <div className="mb-3" controlId="formBasicConfirmPassword">
//                   <label>Confirm Password</label>
//                   <Input
//                     type="password"
//                     placeholder="Confirm password"
//                     value={confirmPassword}
//                     onChange={handleConfirmPasswordChange}
//                   />
//                 </div>
//               </>
//             )}
//             <Button
//               type="submit"
//               onClick={showOtpAndNewPasswordInputs ? resetPassword : sendOtp}
//             >
//               {showOtpAndNewPasswordInputs ? "Reset Password" : "Submit"}
//             </Button>
//             <ToastContainer />
//           </form>

//           <hr />
//           <p className="logintext text-success" onClick={() => navigate("/")}>
//             Login here
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgetPass;



import axios from "../../config/axiosAuth";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import "./login.css";
import authService from "../../service/authServices";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";

const ForgetPass = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOtpAndNewPasswordInputs, setShowOtpAndNewPasswordInputs] =
    useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    try {
      await authService.sendOtpAdmin(email);
      setShowOtpAndNewPasswordInputs(true);
    } catch (error) {
      // toast.error("Error sending OTP");
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      await authService.resetPasswordAdmin(email, otp, newPassword);
      // toast.success("Password reset successfully");
      navigate("/");
    } catch (error) {
      // toast.error("Error resetting password");
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{ width: "30rem", height: "auto" }}
        className="border-2 p-4 rounded-lg"
      >
        <div className="grid gap-3">
          <h2 className="text-2xl">Reset Password</h2>

          <h3 className="forgettext">
            Please enter your email address and we'll send your email an OTP to
            reset your password.
          </h3>
          <form className="emailForm">
            <div className="mb-3" controlId="formBasicEmail">
              <label>Email address</label>
              <Input
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            {showOtpAndNewPasswordInputs && (
              <>
                <div className="mb-3" controlId="formBasicOTP">
                  <label>OTP</label>
                  <Input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={handleOtpChange}
                  />
                </div>
                <div className="mb-3" controlId="formBasicNewPassword">
                  <label>New Password</label>
                  <div className="input-group">
                    <Input
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={toggleNewPasswordVisibility}
                      >
                        {showNewPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-3" controlId="formBasicConfirmPassword">
                  <label>Confirm Password</label>
                  <div className="input-group">
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            <Button
              type="submit"
              onClick={showOtpAndNewPasswordInputs ? resetPassword : sendOtp}
            >
              {showOtpAndNewPasswordInputs ? "Reset Password" : "Submit"}
            </Button>
            <ToastContainer />
          </form>

          <hr />
          <p className="logintext text-success" onClick={() => navigate("/")}>
            Login here
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgetPass;