// import React, { useCallback, useEffect, useRef, useState } from "react";
// import io from "socket.io-client";
// import { base, baseUrl } from "../../config/baseUrl";
// import { ZegoExpressEngine } from "zego-express-engine-webrtc";
// import { useOutletContext } from "react-router-dom";
// import authService from "../../service/authServices";
// import { useQuery } from "react-query";
// import { BsThreeDotsVertical } from "react-icons/bs";
// import { FaSquareShareNodes } from "react-icons/fa6";
// import {
//   Fade,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Menu,
//   MenuItem,
//   Tooltip,
// } from "@mui/material";
// import axios from "../../config/axiosAuth";
// import { toast } from "react-toastify";
// import useScreenShareStore from "../../store/ScreenShare";
// const Admin = () => {
//   const activeConnections = useRef({});
//   // const socketRef = useRef();
//   // const ZegoRef=useRef();
//   const streamIDRef = useRef(null);
//   const remoteVideoRef = useRef(null);
//   const remoteVideoStream = useRef([]);
//   const roomID = useRef(null);
//   const myUserId = useRef(null);
//   const [socketRef, zegoRef,peerConnection] = useOutletContext();
//   const [remoteVideoList, setRemoteVideoList] = useState();
//   const [refsSet, setRefsSet] = useState(false);
//   const { callStarted, setCallStarted, screenShareRoom, setScreenShareRoom } =
//     useScreenShareStore();
//   // const remoteVideoRef=useRef(null)
//   const [showModalOfVideoActions, setShowModalOfVideoActions] = useState(null);
  
//   // const peerConnection = useRef(null);
//   // const peerConnection = new RTCPeerConnection(configuration);

 
//   useEffect(() => {
//     const handleSocketMessage = async (message) => {
//       if (message.offer) {
//         // If an offer is received, handle it by setting the remote description and creating an answer.
//         await peerConnection.current.setRemoteDescription(
//           new RTCSessionDescription(message.offer)
//         );
//         const answer = await peerConnection.current.createAnswer();
//         await peerConnection.current.setLocalDescription(answer);
//         // Send the answer to the other peer.
//         socketRef.current.emit("signal", { answer });
//       } else if (message.answer) {
//         // If an answer is received, set it as the remote description.
//         const remoteDesc = new RTCSessionDescription(message.answer);
//         await peerConnection.current.setRemoteDescription(remoteDesc);
//       } else if (message.candidate) {
//         // Handle incoming ICE candidates.
//         await peerConnection.current.addIceCandidate(new RTCIceCandidate(message.candidate));
//       }
//     };
  
//     // Listen for 'message' event from the server (offers, answers, and ICE candidates).
//     socketRef.current?.on("signal", handleSocketMessage);
  
//     // Handle ICE candidate gathering from local peer connection.
//     peerConnection.current?.addEventListener("icecandidate", (event) => {
//       if (event.candidate) {
//         // Send ICE candidates to the other peer via WebSocket.
//         socketRef.current.emit("iceCandidate", { candidate: event.candidate });
//       }
//     });
  
//     // Listen for incoming ICE candidates via WebSocket.
//     socketRef.current?.on("iceCandidate", async (message) => {
//       if (message.candidate) {
//         await peerConnection.current.addIceCandidate(new RTCIceCandidate(message.candidate));
//       }
//     });
  
//     // Function to create an offer if no offer is received
//     const createAndSendOffer = async () => {
//       const offer = await peerConnection.current.createOffer();
//       await peerConnection.current.setLocalDescription(offer);
//       // Send the offer to the other peer via WebSocket
//       socketRef.current.emit("signal", { offer });
//     };
  
//     // When the component mounts, wait for a bit to see if there's an offer coming in
//     const checkForOffer = setTimeout(() => {
//       if (!peerConnection.current.remoteDescription) {
//         // If no remote description (offer) has been received, create an offer.
//         createAndSendOffer();
//       }
//     }, 3000); // Adjust the timeout as needed
  
//     // Clean-up function to remove event listeners and timeouts on component unmount
//     return () => {
//       clearTimeout(checkForOffer);
//       socketRef.current?.off("signal", handleSocketMessage);
//       socketRef.current?.off("iceCandidate");
//       // peerConnection.current?.removeEventListener("icecandidate");
//     };
//   }, []);
//   console.log(peerConnection.current,'socketss',socketRef.current)

// //   useEffect(() => {
// // console.log(socketRef.current,peerConnection?.current)
    
// //     peerConnection.current?.on("signal", (data) => {
// //       try {
// //         socketRef.current.on("signal", (data) => {
// //           console.log(data, "signalling");
// //         });
// //       } catch (err) {
// //         console.log(err);
// //         throw new Error(err);
// //       }
// //     });
// //     peerConnection?.current?.on("track", async (event) => {
// //       const [remoteStream] = event.streams;
// //       remoteVideoRef.current.srcObject = remoteStream;
// //     });
// //   }, [peerConnection]);

//   // useEffect(() => {
//   //   // setTimeout(() => {
//   //   //   socketRef.current.emit("adminScreenShareRoomJoin");
//   //   //   socketRef.current.on("adminJoinedSuccesfully", (data) => {
//   //   //     roomID.current = data.roomId;
//   //   //     setScreenShareRoom(data.roomId)
//   //   //     myUserId.current = data.userId;
//   //   //     const streamIds = data.streamIds;
//   //   //     setRemoteVideoList(streamIds);
//   //   //     console.log(data, "jointimestreams");
//   //   //     startCall();
//   //   //   });
//   //   //   socketRef.current.on("recieveRemoteScreens", (data) => {
//   //   //     console.log(data, "recieveRemoteScreens");
//   //   //     const updatedStreams = data.streamIds;
//   //   //     setRemoteVideoList(updatedStreams);
//   //   //   });

//   //   // }, 0.5000);
//   // }, []);

//   // const startCall = async () => {
//   //   console.log('enetere,startcall')
//   //   zegoRef.current.setDebugVerbose(false);
//   //   zegoRef.current.on(
//   //     "roomStateChanged",
//   //     async (roomID, reason, errorCode, extendedData) => {
//   //       if (reason == "LOGINED") {
//   //         console.log(
//   //           "Connected to the room successfully. You can perform operations such as stream publishing and playing only after the room is successfully connected.",
//   //           roomID,
//   //           reason,
//   //           errorCode,
//   //           extendedData
//   //         );
//   //       } else if (reason == "LOGIN_FAILED") {
//   //         console.log("failed to login ");
//   //       }
//   //     }
//   //   );
//   //   zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
//   //     console.log(roomId, updateType, userList, "userlist");
//   //   });
//   //   zegoRef.current.on(
//   //     "roomStreamUpdate",
//   //     async (roomId, updateType, streamList, extendedData) => {
//   //       if (updateType == "ADD") {
//   //         console.log(streamList, "streamList");
//   //         console.log();
//   //       } else if ((updateType = "DELETE")) {
//   //         const streamID = streamList[0].streamID;
//   //         console.log(streamList, "re", remoteVideoList);
//   //         zegoRef.current.stopPlayingStream(streamID);
//   //       }
//   //     }
//   //   );

//   //   const configOption = {
//   //     videoBitrate: 300,
//   //     audioBitrate: 48,
//   //     camera: {
//   //       audio: true,
//   //       video: {
//   //         quality: 4,
//   //         width: 480,
//   //         height: 480,
//   //         frameRate: 15,
//   //       },
//   //     },
//   //   };
//   //   const token = localStorage.getItem("zegoToken");
//   //   console.log(token, "ttttoooto");
//   //   try {
//   //     const loginResponse = await zegoRef.current.loginRoom(
//   //       roomID.current,
//   //       token,
//   //       { userID: myUserId.current, userName: Admin?.name },
//   //       { userUpdate: true },
//   //       { isUserStatusNotify: true }
//   //     );
//   //     if (loginResponse == true) {
//   //       console.log("loginned hurrey");
//   //       setCallStarted(true);
//   //     } else if (loginResponse !== true) {
//   //       console.error("Failed to login to the room");
//   //       alert(
//   //         "Failed to login to the room. Please check your credentials and try again."
//   //       );
//   //     }

//   //     zegoRef.current.on("publisherStateUpdate", (result) => {
//   //       var state = result["state"];
//   //       var streamID = result["streamID"];
//   //       var errorCode = result["errorCode"];
//   //       var extendedData = result["extendedData"];
//   //       if (state == "PUBLISHING") {
//   //         console.log(
//   //           "Successfully published an audio and video stream:",
//   //           streamID
//   //         );
//   //       } else if (state == "NO_PUBLISH") {
//   //         console.log("No audio and video stream published");
//   //       } else if (state == "PUBLISH_REQUESTING") {
//   //         console.log(
//   //           "Requesting to publish an audio and video stream:",
//   //           streamID
//   //         );
//   //       }
//   //       console.log("Error code:", errorCode, " Extra info:", extendedData);
//   //       console.log(errorCode, "huhuuuuuhu");
//   //       setCallStarted(true);
//   //       if (errorCode !== 0) {
//   //         console.log("please refresh your page and retry ");
//   //       }
//   //     });
//   //     zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
//   //       console.log(roomId, updateType, userList);
//   //       if (updateType === "DELETE") {
//   //         console.log("user Left");
//   //       } else if (updateType === "ADD") {
//   //         console.log("userJoined");
//   //       }
//   //     });

//   //     const config = {
//   //       logLevel: "disable",
//   //       remoteLogLevel: "disable",
//   //       logURL: "disable",
//   //     };
//   //     zegoRef.current.setLogConfig(config);
//   //   } catch (err) {
//   //     console.log(err, "login error huhuhu");
//   //   }
//   //   try {
//   //     zegoRef.current.on(
//   //       "roomStateUpdate",
//   //       (roomId, state, errorCode, extendedData) => {
//   //         console.log(
//   //           roomId,
//   //           state,
//   //           errorCode,
//   //           extendedData,
//   //           "roomStateUpdate"
//   //         );
//   //         if (errorCode === 1000017) {
//   //         }
//   //         console.log(errorCode, extendedData, "heheheh");
//   //       }
//   //     );
//   //   } catch (err) {
//   //     console.log(err);
//   //   }
//   // };

//   // const PlayRemoteStreams = useCallback(
//   //   async (streamID, videoElement) => {
//   //     if (callStarted) {
//   //       console.log("playstart", streamID);
//   //       await zegoRef.current.stopPlayingStream(streamID);
//   //       const remoteStream = await zegoRef.current.startPlayingStream(streamID);
//   //       console.log("playstart", streamID, remoteStream);
//   //       videoElement.srcObject = remoteStream;
//   //     }
//   //   },
//   //   [callStarted]
//   // );
//   const MenuOfVideoClicked = (id) => {
//     console.log("hey i got clicked ", id);
//     if (id.id !== showModalOfVideoActions) {
//       setShowModalOfVideoActions(id.id);
//     } else {
//       setShowModalOfVideoActions(null);
//     }
//   };
//   const requestReshareScreen = (id) => {
//     try {
//       axios
//         .post(baseUrl + "/sendNotificationToEmployees", {
//           title: "Reshare Request",
//           body: "Please navigate back to dashboard and reshare your screen",
//           to: [id.id],
//         })
//         .then((response) => {
//           toast.success(response?.data?.message);
//           setShowModalOfVideoActions(null);
//           socketRef.current.emit("removeUserFromScreenShareRoom", {
//             userId: id.id,
//             room: roomID.current || screenShareRoom,
//           });
//         })
//         .catch((err) => {
//           toast.error(err?.response?.data?.message);
//         });
//     } catch (err) {
//       console.log(err);
//     }
//   };
//   console.log(showModalOfVideoActions);
//   useEffect(() => {
//     console.log();
//     if (refsSet) {
//       console.log("playing", callStarted);
//       remoteVideoList.forEach((stream, index) => {
//         // PlayRemoteStreams(stream.streamId, remoteVideoStream.current[index]);
//       });
//     }
//   }, [remoteVideoList, refsSet, , callStarted]);

//   console.log(remoteVideoList, "remotevideoListttt", remoteVideoStream.current);
//   return (
//     <>
//       {/* <div>
//         <button
//           type="btnn"
//           className="screenshare_btnnn"
//           style={{
//             backgroundColor: "blue",
//             color:"white",
//             borderRadius: "5px",
//             width: "8vw",
//             height: "5vh",
//             border: "none",
//           }}
//         >
//           Screen Sharing
//         </button>
//         <button 
//          style={{
//           backgroundColor: "blue",
//           borderRadius: "5px",
//           width: "8vw",
//           height: "5vh",
//           border: "none",
//           color:"white"
//         }}
//         type="btnn" className="screenshare_btnnn">
//           Listing
//         </button>
//       </div> */}
//       <h1>DO NOT CLOSE THE SCREEN click here</h1>
//       <div id="vidoes"></div>
//       {remoteVideoList && remoteVideoList.length > 0 && (
//         <div className={"remote_video_section"}>
//           {remoteVideoList?.map((streamID, index) => (
//             <div
//               onClick={() => console.log(streamID, "this videp clicked")}
//               className={"double_remote_video_container"}
//               style={{
//                 width:
//                   remoteVideoList.length === 1
//                     ? "100%"
//                     : remoteVideoList.length <= 4
//                     ? "calc(50% - 2px )"
//                     : remoteVideoList.length >= 4
//                     ? "calc(25% - 2px )"
//                     : "calc(25% - 2px )",
//                 height:
//                   remoteVideoList.length <= 2
//                     ? "100%"
//                     : remoteVideoList.length <= 8
//                     ? "calc(50% - 2px )"
//                     : remoteVideoList.length > 8
//                     ? "calc(33% - 2px )"
//                     : "calc(33% - 2px )",
//               }}
//             >
//               <video
//                 onClick={() => console.log(streamID, "this videp clicked")}
//                 key={streamID.streamId}
//                 autoPlay={true}
//                 playsInline
//                 ref={(el) => {
//                   remoteVideoStream.current[index] = el;
//                   if (index === remoteVideoList.length - 1) {
//                     setRefsSet(true);
//                   }
//                 }}
//                 style={{ transform: "scaleX(1)" }}
//                 className="remote_video"
//               ></video>
//               <div
//                 style={{
//                   position: "absolute",
//                   top: "10px",
//                   right: "10px",
//                   zIndex: "9",
//                   color: "white",
//                 }}
//               >
//                 <div style={{ position: "relative" }}>
//                   <div onClick={() => MenuOfVideoClicked(streamID)}>
//                     <BsThreeDotsVertical />
//                   </div>
//                   {showModalOfVideoActions === streamID.id && (
//                     <div
//                       style={{
//                         width: "200px",
//                         height: "100px",
//                         position: "absolute",
//                         background: "white",
//                         borderRadius: "10px",
//                         top: " 20px",
//                         right: " 0",
//                         boxShadow: "3px 3px 6px #000",
//                         color: "#085394",
//                         fontSize: "12px",
//                       }}
//                     >
//                       <List>
//                         <Tooltip
//                           title={`Request ${streamID.name} to reshare/share their screen`}
//                         >
//                           <ListItem
//                             onClick={() => requestReshareScreen(streamID)}
//                           >
//                             <ListItemIcon>
//                               <FaSquareShareNodes
//                                 color="#085394"
//                                 style={{ width: "28px", height: "28px" }}
//                               />
//                             </ListItemIcon>
//                             <ListItemText sx={{ fontSize: "14px" }}>
//                               Request Reshare{" "}
//                             </ListItemText>
//                           </ListItem>
//                         </Tooltip>
//                         {/* <ListItem>  <ListItemText></ListItemText></ListItem>
//                     <ListItem>  <ListItemText></ListItemText></ListItem> */}
//                       </List>
//                     </div>
//                   )}
//                 </div>
//               </div>
//               <div
//                 style={{
//                   position: "absolute",
//                   top: "10px",
//                   left: "10px",
//                   zIndex: "9",
//                   color: "white",
//                 }}
//               >
//                 {streamID.name}
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </>
//   );
// };

// export default Admin;
import React, { useCallback, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { base, baseUrl } from "../../config/baseUrl";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { useOutletContext } from "react-router-dom";
import authService from "../../service/authServices";
import { useQuery } from "react-query";
import { BsPeopleFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaSquareShareNodes } from "react-icons/fa6";
import {
  Avatar,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import axios from "../../config/axiosAuth";
import { toast } from "react-toastify";
import useScreenShareStore from "../../store/ScreenShare";
import { PersonIcon } from "@radix-ui/react-icons";
import { cn } from "../../../utils/lib";
const Admin = () => {
  const activeConnections = useRef({});
  // const socketRef = useRef();
  // const ZegoRef=useRef();

  const streamIDRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const remoteVideoStream = useRef([]);
  const roomID = useRef(null);
  const myUserId = useRef(null);
  const [socketRef, zegoRef] = useOutletContext();
  const [remoteVideoList, setRemoteVideoList] = useState();
  const [refsSet, setRefsSet] = useState(false);
  const {callStarted,setCallStarted,screenShareRoom,setScreenShareRoom}=useScreenShareStore()
const[activeUsersList,setActiveUsersList]=useState([])
const[showActiveUsersList,setShowActiveUsersList]=useState(false)
  const [showModalOfVideoActions, setShowModalOfVideoActions] = useState(null);
  
  useEffect(() => {
    setTimeout(() => {
      socketRef.current.emit("adminScreenShareRoomJoin");
      socketRef.current.on("adminJoinedSuccesfully", (data) => {
        roomID.current = data.roomId;
        setScreenShareRoom(data.roomId)
        myUserId.current = data.userId;
        const streamIds = data.streamIds;
        setRemoteVideoList(streamIds);
        console.log(data, "jointimestreams");
        startCall();
      });
      socketRef.current.on("recieveRemoteScreens", (data) => {
        console.log(data, "recieveRemoteScreens");
        const updatedStreams = data.streamIds;
        setRemoteVideoList(updatedStreams);
      });
     
    }, 0.5000);
  }, []);

  const startCall = async () => {
    console.log('enetere,startcall')
    zegoRef.current.setDebugVerbose(false);
    zegoRef.current.on(
      "roomStateChanged",
      async (roomID, reason, errorCode, extendedData) => {
        if (reason == "LOGINED") {
          console.log(
            "Connected to the room successfully. You can perform operations such as stream publishing and playing only after the room is successfully connected.",
            roomID,
            reason,
            errorCode,
            extendedData
          );
        } else if (reason == "LOGIN_FAILED") {
          console.log("failed to login ");
        }
      }
    );
    zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
      console.log(roomId, updateType, userList, "userlist");
    });
    zegoRef.current.on(
      "roomStreamUpdate",
      async (roomId, updateType, streamList, extendedData) => {
        if (updateType == "ADD") {
          console.log(streamList, "streamList");
          console.log();
        } else if ((updateType = "DELETE")) {
          const streamID = streamList[0].streamID;
          console.log(streamList, "re", remoteVideoList);
          zegoRef.current.stopPlayingStream(streamID);
        }
      }
    );

    const configOption = {
      videoBitrate: 300,
      audioBitrate: 48,
      camera: {
        audio: true,
        video: {
          quality: 4,
          width: 480,
          height: 480,
          frameRate: 15,
        },
      },
    };
    const token = localStorage.getItem("zegoToken");
    console.log(token, "ttttoooto");
    try {
      const loginResponse = await zegoRef.current.loginRoom(
        roomID.current,
        token,
        { userID: myUserId.current, userName: Admin?.name },
        { userUpdate: true },
        { isUserStatusNotify: true }
      );
      if (loginResponse == true) {
        console.log("loginned hurrey");
        setCallStarted(true);
      } else if (loginResponse !== true) {
        console.error("Failed to login to the room");
        alert(
          "Failed to login to the room. Please check your credentials and try again."
        );
      }

      zegoRef.current.on("publisherStateUpdate", (result) => {
        var state = result["state"];
        var streamID = result["streamID"];
        var errorCode = result["errorCode"];
        var extendedData = result["extendedData"];
        if (state == "PUBLISHING") {
          console.log(
            "Successfully published an audio and video stream:",
            streamID
          );
        } else if (state == "NO_PUBLISH") {
          console.log("No audio and video stream published");
        } else if (state == "PUBLISH_REQUESTING") {
          console.log(
            "Requesting to publish an audio and video stream:",
            streamID
          );
        }
        console.log("Error code:", errorCode, " Extra info:", extendedData);
        console.log(errorCode, "huhuuuuuhu");
        setCallStarted(true);
        if (errorCode !== 0) {
          console.log("please refresh your page and retry ");
        }
      });
      zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
        console.log(roomId, updateType, userList);
        if (updateType === "DELETE") {
          console.log("user Left");
        } else if (updateType === "ADD") {
          console.log("userJoined");
        }
      });

      const config = {
        logLevel: "disable",
        remoteLogLevel: "disable",
        logURL: "disable",
      };
      zegoRef.current.setLogConfig(config);
    } catch (err) {
      console.log(err, "login error huhuhu");
    }
    try {
      zegoRef.current.on(
        "roomStateUpdate",
        (roomId, state, errorCode, extendedData) => {
          console.log(
            roomId,
            state,
            errorCode,
            extendedData,
            "roomStateUpdate"
          );
          if (errorCode === 1000017) {
          }
          console.log(errorCode, extendedData, "heheheh");
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
 

  

  const PlayRemoteStreams = useCallback(
    async (streamID, videoElement) => {
      if (callStarted) {
        console.log("playstart", streamID);
        await zegoRef.current.stopPlayingStream(streamID);
        const remoteStream = await zegoRef.current.startPlayingStream(streamID);
        console.log("playstart", streamID, remoteStream);
        videoElement.srcObject = remoteStream;
      }
    },
    [callStarted]
  );
  const MenuOfVideoClicked = (id) => {
    console.log("hey i got clicked ", id);
    if (id.id !== showModalOfVideoActions) {
      setShowModalOfVideoActions(id.id);
    } else {
      setShowModalOfVideoActions(null);
    }
  };
  const requestReshareScreen = (id) => {
    try {
      axios
        .post(baseUrl + "/sendNotificationToEmployees", {
          title: "Reshare Request",
          body: "Please navigate back to dashboard and reshare your screen",
          to: [id.id],
        })
        .then((response) => {
          // toast.success(response?.data?.message);
          setShowModalOfVideoActions(null);
          socketRef.current.emit('removeUserFromScreenShareRoom',{userId:id.id,room:roomID.current||screenShareRoom})
          setTimeout(()=>{
    socketRef.current.emit('requestReshare',{userId:id.id})

          },2000)
        })
        .catch((err) => {
          // toast.error(err?.response?.data?.message);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const requestShareScreen = (id) => {
    try {
      axios
        .post(baseUrl + "/sendNotificationToEmployees", {
          title: "Reshare Request",
          body: "Please navigate back to dashboard and reshare your screen",
          to: [id],
        })
        .then((response) => {
          // toast.success(response?.data?.message);
          setShowActiveUsersList(false)
        })
        .catch((err) => {
          // toast.error(err?.response?.data?.message);
        });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(()=>{
    getActiveUsers()
  },[])
  const getActiveUsers = (id) => {
    try {
      axios
        .get(baseUrl + "/active-users", {
        
        })
        .then((response) => {
          console.log(response,'active-users')
          setActiveUsersList(response.data.data)
        })
        .catch((err) => {
          console.log('err',err)
        });
    } catch (err) {
      console.log(err);
    }
  };
  console.log(showModalOfVideoActions);
  useEffect(() => {
    console.log();
    if (refsSet) {
      console.log("playing", callStarted);
      remoteVideoList.forEach((stream, index) => {
        PlayRemoteStreams(stream.streamId, remoteVideoStream.current[index]);
      });
    }
  }, [remoteVideoList, refsSet, PlayRemoteStreams, callStarted]);

  console.log(remoteVideoList, "remotevideoListttt", remoteVideoStream.current);
  return (
    <>
      {/* <div>
        <button
          type="btnn"
          className="screenshare_btnnn"
          style={{
            backgroundColor: "blue",
            color:"white",
            borderRadius: "5px",
            width: "8vw",
            height: "5vh",
            border: "none",
          }}
        >
          Screen Sharing
        </button>
        <button 
         style={{
          backgroundColor: "blue",
          borderRadius: "5px",
          width: "8vw",
          height: "5vh",
          border: "none",
          color:"white"
        }}
        type="btnn" className="screenshare_btnnn">
          Listing
        </button>
      </div> */}
      <h1>DO NOT CLOSE THE SCREEN click here</h1>
<div className="ml-auto flex justify-end relative"><BsPeopleFill onClick={()=>setShowActiveUsersList(!showActiveUsersList)} className="cursor-pointer"/>

{showActiveUsersList&&<div className="absolute bg-white w-[250px] h-[300px] overflow-auto top-[20px] z-[99] pt-4 flex flex-col gap-2 shadow-xl rounded-[5px]">
  {
    activeUsersList?.map((item)=>(<div className="flex w-full p-4 h-[35px] hover:bg-[lightGrey] items-center justify-between text-left"><Avatar className="!w-[30px] !h-[30px] !text-[13px] ">{item.name?.[0]}</Avatar><div className="w-[60%] flex items-center justify-start gap-[2px]">{item.name} <div className={cn("w-[10px] h-[10px] rounded-[50%]",{'bg-[green]':item.isOnline,
      "bg-[red]":!item.isOnline
    })}></div> </div> <FaSquareShareNodes onClick={()=>requestShareScreen(item._id)}
    color="#085394"
    style={{ width: "28px", height: "28px" ,cursor:'pointer'}}
  /></div>))
  }
  </div>}
</div>
      <div className="w-full overflow-auto" style={{height:'calc(100% - 40px)'}}>
      <div id="vidoes"></div>
      {remoteVideoList && remoteVideoList.length > 0 && (
        <div className={"remote_video_section"}>
          {remoteVideoList?.map((streamID, index) => (
            <div
              onClick={() => console.log(streamID, "this videp clicked")}
              className={"double_remote_video_container"}
              style={{
                width:
                  remoteVideoList.length === 1
                    ? "100%"
                    : remoteVideoList.length <= 4
                    ? "calc(50% - 2px )"
                    : remoteVideoList.length >= 4
                    ? "calc(25% - 2px )"
                    : "calc(25% - 2px )",
                height:
                  remoteVideoList.length <= 2
                    ? "100%"
                    : remoteVideoList.length <= 8
                    ? "calc(50% - 2px )"
                    : remoteVideoList.length > 8
                    ? "calc(33% - 2px )"
                    : "calc(33% - 2px )",
              }}
            >
              <video
                onClick={() => console.log(streamID, "this videp clicked")}
                key={streamID.streamId}
                autoPlay={true}
                playsInline
                ref={(el) => {
                  remoteVideoStream.current[index] = el;
                  if (index === remoteVideoList.length - 1) {
                    setRefsSet(true);
                  }
                }}
                style={{ transform: "scaleX(1)" }}
                className="remote_video"
              ></video>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: "9",
                  color: "white",
                }}
              >
                <div style={{ position: "relative" }}>
                  <div onClick={() => MenuOfVideoClicked(streamID)}>
                    <BsThreeDotsVertical />
                  </div>
                  {showModalOfVideoActions === streamID.id && (
                    <div
                      style={{
                        width: "200px",
                        height: "100px",
                        position: "absolute",
                        background: "white",
                        borderRadius: "10px",
                        top: " 20px",
                        right: " 0",
                        boxShadow: "3px 3px 6px #000",
                        color: "#085394",
                        fontSize: "12px",
                      }}
                    >
                      <List>
                        <Tooltip
                          title={`Request ${streamID.name} to reshare/share their screen`}
                        >
                          <ListItem
                            onClick={() => requestReshareScreen(streamID)}
                          >
                            <ListItemIcon>
                              <FaSquareShareNodes
                                color="#085394"
                                style={{ width: "28px", height: "28px" }}
                              />
                            </ListItemIcon>
                            <ListItemText sx={{ fontSize: "14px" }}>
                              Request Reshare{" "}
                            </ListItemText>
                          </ListItem>
                        </Tooltip>
                        {/* <ListItem>  <ListItemText></ListItemText></ListItem>
                    <ListItem>  <ListItemText></ListItemText></ListItem> */}
                      </List>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  zIndex: "9",
                  color: "white",
                }}
              >
                {streamID.name}
              </div>
            </div>
          ))}
        </div>
      )}
      </div>
    </>
  );
};

export default Admin;