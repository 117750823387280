import { baseUrl } from "../config/baseUrl";
import axios from "./../config/axiosAuth";
export const getAllTickets = async () => {
  const res = await axios.get(baseUrl + "/tickets");
  return res.data.result;
};

export const addTicket = async (data) => {
  const res = await axios.post(baseUrl + "/addTicket", data);
  return res.data.result;
};

export const getTicketbyProjectId=async(data)=>{
  const res= await axios.post(baseUrl+"/ticketByProject",{
    projectId:data.projectId,
    module:data.testModule
  })
  return res.data.tickets
}