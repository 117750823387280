import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { renderProtectedRoute } from "./App.js";
import ForgetPass from "./admin/pages/login/ForgetPass";
import Login from "./admin/pages/login/Login";
import ProtectRoute from "./admin/pages/protectedPages/ProtectRoute";
import Admin from "./admin/pages/screens/Screens.jsx";
import { routes } from "./routes.js";
import SocketProvider from "./admin/Contexts/SocketProvider.jsx";

export function AppContainer() {
  return (
    <BrowserRouter>
      <Routes>
    <Route path="/" element={<Navigate replace to="/login" />} />

        <Route path="/login" index element={<ProtectRoute Component={Login} />} />
        <Route element={<SocketProvider />}>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={renderProtectedRoute(route.component)}
            />
          ))}
        </Route>

        <Route path="/forgetPass" element={<ForgetPass />} />
        <Route path="/screen" element={<Admin />} />
      </Routes>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={1000}
        hideProgressBar
        closeOnClick
        limit={4}
      />
    </BrowserRouter>
  );
}
