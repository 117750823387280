/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import { useEffect, useState } from "react";
import { transformEvents } from "../../../utils/transformData";
import Calendar from "../../components/calender/Calender";
import { Catagory } from "../../components/calender/Catagory";
import Widget from "../../components/widget/Widget";
import useEventHook from "../../hooks/eventsHooks";
import Error from "../protectedPages/Error";
import Loading from "../protectedPages/Loading";
import Graph from "./../../components/graph/Graph";
import { Link, useNavigate } from "react-router-dom";
import BookShelf from "../../../../src/Assets/images/BookShelf.png";
import Group343 from "../../../../src/Assets/images/Group343.png";
import Group330 from "../../../../src/Assets/images/Group330.png";
import Group332 from "../../../../src/Assets/images/Group332.png";
import Group334 from "../../../../src/Assets/images/Group343.png";
import "./style.css";
import useGetByCategory from "../../hooks/employee/getByCategory";
import { SiGooglemessages } from "react-icons/si";
import DougnutCharts from "./DougnutCharts";
import Growthchart from "../Dashboard/Growthchart";
import Sales from "../Dashboard/Sales";
import { Api } from "@mui/icons-material";
import axios from "../../config/axiosAuth";
import { base, baseUrl, baseUrlCommon } from "../../config/baseUrl";
import useProjectHook from "../../hooks/useProjectHook";
import ProjectPerformanceChart from "./ProjectPerformanceChart";
import authService from "../../service/authServices";
import { useQuery } from "react-query";

export function AdminDashboard() {
  const [events, setEvent] = useState([]);
  const { allEvents, allEventsLoading, allEventsIsError } = useEventHook();
  const [selectedProject, setSelectedProject] = useState("");
  const [recentActivites, setRecentActivities] = useState([]);
  const [seletedprojectperformance, setSeletedprojectperformance] = useState(
    []
  );
  const getEmployeeByCategory = useGetByCategory();
  const [leaves, setLeaves] = useState({});
  const [emplyeeData, setEmployeeData] = useState({});
const [count,setCount ]=useState({hybrid:null,wfh:null,})
  const getEmployeeByCategoryData = getEmployeeByCategory.data;
  const params={
    searchTerm:''
  }
  const { data: projectsData,totalCompletedProjects } = useProjectHook(params);
  useEffect(() => {
    const transformedEvents = transformEvents(allEvents);
    setEvent(transformedEvents);
  }, [allEvents]);

  useEffect(() => {
    if (Array.isArray(projectsData) && projectsData.length > 0) {
      const projectId = projectsData[0]?._id;
      setSelectedProject(projectId);
      setSeletedprojectperformance(projectId);
    }
  }, [projectsData]);

  useEffect(() => {
    handledashboardbox();
    handledashboardapi();
    FetchRecentActivites();
  }, []);
  const GetToken = async () => {
    const res = await authService.zegoToken();
    return res;
  };
  const {
    data: zegoToken,
    isLoading,
    isError,
  } = useQuery('zegoToken', GetToken, {
    cacheTime: 0, 
    staleTime: 0, 
    onSuccess: (data) => {
      localStorage.setItem("zegoToken", data.token);
    },
  });
  useEffect(()=>{
    fetchDataofEmpoyeeswfh()
    fetchDataofEmpoyeesHybrid()
  },[])
  const handledashboardbox = () => {
    axios
      .get(baseUrl + "/eventData", {})

      .then((res) => {
        setLeaves(res.data.result);
      })

      .catch((err) => {
      });
  };
  const navigate =useNavigate()
  const FetchRecentActivites = async () => {
    try {
      const response = await axios.get(baseUrl + "/getActivities");
      setRecentActivities(response.data.result);
    } catch (err) {
    }
  };
  const handledashboardapi = () => {
    axios
      .get(baseUrl + "/employeeData", {})

      .then((res) => {
        setEmployeeData(res.data);
      })

      .catch((err) => {
      });
  };

  if (allEventsIsError) {
    return <Error />;
  }

   const fetchDataofEmpoyeeswfh = async () => {
    try {
      const response = await axios.post(baseUrl + "/employeeListByWorkMode", {
        workMode: 'wfh',
      });
     setCount((prev)=>({
      ...prev,
      wfh:response.data.result.length
      
     }))
    } catch (err) {
      throw new Error(err);
    }
  };
  const fetchDataofEmpoyeesHybrid = async () => {
    try {
      const response = await axios.post(baseUrl + "/employeeListByWorkMode", {
        workMode: "Hybrid",
      });
      setCount((prev)=>({
        ...prev,
        hybrid:response.data.result.length
        
       }))
    } catch (err) {
      throw new Error(err);
    }
  };

  if (getEmployeeByCategory.isLoading) return <Loading />;

  const Cards = [
    {
      title: "TOTAL EMPLOYEES",
      icon: BookShelf,
      data: emplyeeData?.totalEmployee,
      onClick:()=>navigate('/team')
    },
    {
      title: "EMPLOYEES PRESENT",
      icon: Group343,
      data: emplyeeData?.present,
      onClick:()=>navigate('/presentEmployees')
    },
    {
      title: "EMPLOYEES ABSENT",
      icon: Group330,
      data: emplyeeData?.absent,
      onClick:()=>navigate('/absentEmployees')
    },
    {
      title: "WFH",
      icon: Group332,
      data:count.wfh ,
      onClick:()=>navigate('/workmode',{state:'WFH'})
    },
    {
      title: "HYBRID",
      icon: Group334,
      data: count.hybrid,
      onClick:()=>navigate('/workmode',{state:'HYBRID'})
    },
    {
      title: "PROJECT STATUS",
      icon: Group334,
      data: totalCompletedProjects,
      onClick:()=>navigate('/completedProjects')
    },
  ];

  const ProjectToBefetched = (e) => {

    setSelectedProject(e.target.value);
  };

  const performanceToBeFectchedByProjectId = (e) => {
    setSeletedprojectperformance(e.target.value);
  };

  return (
    <>
      <div className="w-full h-auto flex flex-col px-[5px]">
        <div className="flex justify-between my-6">
          {Cards?.map((item) => (
            <div className=" relative w-[15.5%] min-w-[15.5%] min-h-[100px] cursor-pointer hover:scale-105	" onClick={item.onClick}>
              <div className="bg-white px-2 pt-6 pb-2 flex flex-col justify-between rounded-lg text-[#797979] text-sm w-full h-full ">
                <p className="text-[12px] font-semibold">{item.title}</p>
                <p>
                  <span className="text-3xl text-[#898989] font-bold ">
                    {item.data}
                  </span>
                </p>
              </div>
              <span className="absolute -top-2 -left-2 w-7 h-7">
                <img src={item.icon} alt="icon" />
              </span>
            </div>
          ))}
        </div>

        <div className=" flex justify-between p-4 w-[42vw] bg-white  text-[#898989] rounded-xl">
          <div onClick={(()=>navigate('/dashboard-leave',{state:{data:leaves?.leaves}}))} className="cursor-pointer hover:scale-125">
            <h4>Leaves</h4>
            <span>{leaves?.leaves?.length}</span>
          </div>
          <div onClick={(()=>navigate('/dashboard-holiday',{state:{data:leaves?.holidays}}))} className="cursor-pointer hover:scale-125">
            <h4>Holiday</h4>
            <span>{leaves?.holidays?.length}</span>
          </div>
          <div onClick={(()=>navigate('/dashboard-birthday',{state:{data:leaves?.birthdays}}))} className="cursor-pointer hover:scale-125">
            <h4>Birthday</h4>
            <span>{leaves?.birthdays?.length}</span>
          </div>
        </div>
      </div>
      <DougnutCharts />
      <div></div>

      <div className="flex gap-2 w-full">
        <div className="w-[100%] h-auto flex flex-col ">
          <div className="w-full flex gap-3">
            <div className="w-[60%] h-auto bg-white mt-5 rounded-lg pb-[25px] px-[15px]">
              <>
                <h4 className="ml-4 mt-4 font-bold text-2xl">Growth</h4>
              </>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ marginRight: "1rem" }}>
                  <select
                    style={{ width: "12vw" }}
                    name="Team"
                    id="Team"
                    onChange={ProjectToBefetched}
                    value={selectedProject}
                  >
                    <option value="select">Select Project</option>
                    {projectsData?.map((item) => (
                      <option value={item._id}>{item.projectName}</option>
                    ))}
                  </select>
                </div>
              </div>

              <Growthchart projectId={selectedProject} />
            </div>
            <div className="w-[35%] max-h-[450px] bg-white mt-5 rounded-lg">
              <h3 className="ml-4 mt-4 font-bold text-xl">RECENT ACTIVITY</h3>

              <div className="flex items-center  w-full flex-col overflow-y-auto h-[90%] p-1	">
              { recentActivites && recentActivites?.length > 0? recentActivites?.map((activity) => (
                  <div className="w-full flex justify-between p-2 pt-4">
                    <div>
                      <SiGooglemessages
                        style={{
                          fontSize: "2rem",
                          color: "#5DC983",
                          padding: "0.25rem",
                          //  marginLeft: "18px",
                        }}
                      />
                    </div>
                    <div className="w-[90%]">
                      <p className="whitespace-pre-line	p-1">
                        {activity.activity}
                      </p>
                    </div>
                  </div>
                )):<div className="w-full text-center text-[lightgrey] m-auto">NO RECENT ACTIVITES FOUND</div>}
                {/* <div className="flex">I
              <SiGooglemessages
                style={{
                  fontSize: "2rem",
                  color: "#5DC983",
                  marginLeft: "18px",
                }}
              />
            </div>
            <div>
              <p className="mt-2 text-slate-500">
                <span className="text-slate-900 font-bold ">Vivek</span> is a
                good boy React js Developer
              </p>
              <p>Aug 10</p>aa
            </div>
            

            <div className="flex">
              <SiGooglemessages
                style={{
                  fontSize: "2rem",
                  color: "#5DC983",
                  marginLeft: "18px",
                }}
              />
            </div>
            <div>
              <p className="mt-2 text-slate-500">
                <span className="text-slate-900 font-bold ">Vivek</span> is a
                good boy React js Developer
              </p>
              <p>Aug 10</p> */}
                {/* </div>   */}
              </div>
            </div>
          </div>
          <div className="w-full flex gap-3">
            <div className="w-[60%] h-auto bg-white mt-5 rounded-lg ">
              <>
                <h4 className="ml-4 mt-4 font-bold text-xl">Performance</h4>
              </>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ marginRight: "1rem" }}>
                  <select
                    style={{ width: "12vw" }}
                    name="Team"
                    id="Team"
                    onChange={performanceToBeFectchedByProjectId}
                    value={seletedprojectperformance}
                  >
                    <option value="select">Select Project</option>
                    {projectsData?.map((item) => (
                      <option value={item._id}>{item.projectName}</option>
                    ))}
                  </select>
                </div>
              </div>

              <ProjectPerformanceChart projectId={seletedprojectperformance} />
            </div>
            <div className="w-[30%]  max-h-[450px]   bg-white mt-5 rounded-lg pb-[20px] ">
              <h4 className="ml-4 mt-4 font-bold text-xl"></h4>

              <Sales />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
