import { Avatar } from "@mui/material";
import PeopleSection from "./PeopleSection";
import React, { useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";

const ChatSection = ({ socketRef, userId, roomId,messages, setMessages ,userName}) => {
  const [message, setmessage] = useState("");
  // const [messages, setMessages] = useState([]);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    console.log("component of chat mounted");
   
  }, []);
  console.log(roomId,'roomidddd')

  useEffect(() => {
    console.log("mesages array", messages);
  }, [messages]);

  const sendMessages = () => {
    const data = {
      message: message,
      sender: userId,
      senderName:userName,
      room: roomId.linkId,
      timestamp: new Date().toLocaleTimeString(),
    };
    socketRef.emit("sendMessageInRoom",data);
    console.log(data, "sending message");
  };

  useEffect(() => {
    const chatBox = chatBoxRef.current;

    if (chatBox) {
      const observer = new MutationObserver((mutations) => {
        const hasNewMessages = mutations.some(
          (mutation) =>
            mutation.type === "childList" || mutation.type === "subtree"
        );

        if (hasNewMessages) {
          chatBox.scroll({
            top: chatBox.scrollHeight,
            behavior: "smooth",
          });
        }
      });
      observer.observe(chatBox, {
        childList: true,
        subtree: true,
      });
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setmessage("");
      sendMessages();
      console.log(message, "msgket");
      e.target.value = "";
    }
  };
  return (
    <>
      <div className="people_head">Chat with the people</div>
      <div className="chat_inputs_box" ref={chatBoxRef}>
        {messages.map(
          (msg, i) =>
            msg.message &&
            msg.message !== "" && (
              <div key={i} 
              className="chat_individual"
              style={{marginLeft:msg.sender===userId?'auto':'',marginRight:msg.sender!==userId?'auto':''}}
              >
                {/* <Avatar>{msg.senderName[0]}</Avatar> */}
                {msg.message && msg.message !== "" && (
                  < >
                    {/* <img
                        src={msg.image ? msg.image : "DummyAccount"}
                        alt={"/profile Icon"}
                        className="w-9 h-9 rounded-md flex self-end	"
                      /> */}

                    <p
                      className={''}
                    >
                      {msg.sender && msg.sender === userId ? "You" : msg.senderName}
                    </p>

                    <p
                      className={''}
                    >
                      {msg.message}
                    </p>

                    <p className="timestamp_text">{msg.timestamp}</p>
                  </>
                )}
              </div>
            )
        )}
      </div>
      <div className="button_section">
        <input
          className=''
          placeholder="Type a message..."
          type="text"
          id="sendInput"
          value={message}
          onKeyPress={handleKeyPress}
          onChange={(e) => setmessage(e.target.value)}
        />

        <div
          className={''}
          onClick={sendMessages}
        >
          <IoSend color="white" size="28px" />
        </div>
      </div>
    </>
  );
};

export default ChatSection;
