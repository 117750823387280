import { useQuery } from "react-query";
import axios from "./../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";

const fetchAdmin = async () => {
  const { data } = await axios.get(baseUrl + "/getMyProfile");
  return data.result;
};

const useAdminHook = () => {
  const {
    data: adminProfile,
    isLoading,
    isError,
  } = useQuery("adminProfile", fetchAdmin);

  return { adminProfile, isLoading, isError };
};

export default useAdminHook;
