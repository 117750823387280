import { zodResolver } from "@hookform/resolvers/zod";
import { State } from "country-state-city";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { cn } from "../../../utils/lib";

const addTeamSchema = z.object({
  team: z
    .string()
    .trim()
    .max(50)
    .min(1)
    .regex(/^\S/, "Team Name should not start with a space"), // Ensure the string starts with a non-space character
});

export const AddTeamModal = ({className}) => {
  const [teamName, setTeamName] = useState("");
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(baseUrl + "/addTeam", {
        teamName: teamName,
      });
      setOpen(false);
      queryClient.invalidateQueries("departmentList");
    } catch (err) {
      throw new Error(err);
    }
  };
  const handle = (e) => {
    setTeamName(e.target.value);
  };
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className={cn("bg-[#085394] text-white hover:bg-[#085394]",className)}>
          Add Department
        </Button>
      </DialogTrigger>
      <DialogContent className="h-[30vh] min-h-[200px] overflow-scroll p-4">
        <DialogHeader className="border-b-2">
          <DialogTitle>Add Department</DialogTitle>
        </DialogHeader>
        <form className="space-y-4">
          <div className="flex justify-between">
            <div>
              <label>Department Name</label>

              <input placeholder="Department Name" onChange={handle}></input>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit" onClick={onSubmit}>
              Save Changes
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
